/* eslint-disable no-unused-vars */

import { api } from '@/utils';
import EXPORT_MUTATION_TYPES from './export.mutation-types';

const EXPORT_ACTIONS = {
  [EXPORT_MUTATION_TYPES.FETCH_EXPORT_EVENT_USERS]({ commit }, year) {
    return new Promise((resolve, reject) => {
      commit(EXPORT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      api.get(`export?year=${year}`,
        {
          responseType: 'blob',
        })
        .then((response) => {
          commit(EXPORT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve(response);
        }).catch((error) => {
          commit(EXPORT_MUTATION_TYPES.SET_ERROR, error);
          commit(EXPORT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          reject(error);
        });
    });
  },
};

export default EXPORT_ACTIONS;
