import _ from 'lodash';
import { roundOffCoordinate } from '@/utils';
import isServer from '@/utils/isServer';

if (!isServer) {
  var { LMap, LTileLayer, LMarker } = require('vue2-leaflet');
}
export default {
  name: 'LatLongMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    existingMarker: null,
  },
  data: () => ({
    // this is the provider for the map, this decides how the map will look
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    zoom: 7,
    center: [50.5039, 4.4699],
    markers: [],
  }),
  computed: {
  },
  methods: {
    newMarker(event) {
      if (this.markers.length >= 1) {
        this.markers = [];
      }

      let latLong;
      let lat;
      let long;
      if (typeof event.latlng === 'undefined') {
        lat = roundOffCoordinate(event.lat, 5);
        long = roundOffCoordinate(event.lng, 5);
        latLong = [lat, long];
        this.markers.push({
          id: this.markers.length + 1,
          latLong,
        });
        this.$emit('markerPlaced', latLong);
      } else {
        lat = roundOffCoordinate(event.latlng.lat, 5);
        long = roundOffCoordinate(event.latlng.lng, 5);

        latLong = [lat, long];
        this.markers.push({
          id: this.markers.length + 1,
          latLong,
        });
        this.$emit('markerPlaced', latLong);
      }

      this.center = latLong;
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
  },
  created() {
    if (!_.isEmpty(this.existingMarker)) {
      this.newMarker({ lat: this.existingMarker.latLong[0], lng: this.existingMarker.latLong[1] });
    }
  },
};
