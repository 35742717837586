import { accountStore } from './account';
import { disciplineStore } from './discipline';
import { clubStore } from './club';
import { groupStore } from './group';
import { eventStore } from './event';
import { activityStore } from './activity';
import { challengeStore } from './challenge/index';
import { advertisementStore } from './advertisement/index';
import { tagStore } from './tag/index';
import { exportStore } from './export';

/* ==========================================================================
   STORE MODULES
   ========================================================================== */
// eslint-disable-next-line import/prefer-default-export
export const STORE_MODULES = {
  account: accountStore,
  discipline: disciplineStore,
  club: clubStore,
  group: groupStore,
  event: eventStore,
  activity: activityStore,
  challenge: challengeStore,
  advertisement: advertisementStore,
  tag: tagStore,
  export: exportStore,
};
