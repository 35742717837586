const CHALLENGE_MUTATION_TYPES = Object.freeze({
  FETCH_DATA_CHALLENGE_LIST: 'FETCH_DATA_CHALLENGE_LIST',
  FETCH_DATA_YEAR_RANKING_LIST: 'FETCH_DATA_YEAR_RANKING_LIST',
  FETCH_DATA_MARATHON_RANKING_LIST: 'FETCH_DATA_MARATHON_RANKING_LIST',
  FETCH_DATA_WATERMAN_RANKING_LIST: 'FETCH_DATA_WATERMAN_RANKING_LIST',
  FETCH_DATA_EXPLORER_RANKING_LIST: 'FETCH_DATA_EXPLORER_RANKING_LIST',
  FETCH_DATA_CHALLENGE: 'FETCH_DATA_CHALLENGE',

  SET_CHALLENGE_LIST: 'SET_CHALLENGE_LIST',

  SET_CHALLENGE: 'SET_CHALLENGE',
  SET_RANKING: 'SET_RANKING',

  APPEND_RANKING_LIST: 'APPEND_RANKING_LIST',

  CLEAR_RANKING_LIST: 'CLEAR_RANKING_LIST',

  SET_ERROR: 'SET_ERROR',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_BUTTON_LOADING_STATE: 'SET_BUTTON_LOADING_STATE',
});

export default CHALLENGE_MUTATION_TYPES;
