export default () => ({
  group: {},
  groups: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
  userGroups: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
  buttonLoading: false,
  markers: [],
  groupPicture: '',
  groupFilter: {
    query: '',
    pageSize: 8,
    discipline: null,
    location: null,
  },
  userGroupFilter: {
    page: 1,
    pageSize: 6,
  },
  page: 1,
  status: {
    loading: false,
    followersLoading: false,
    groupsLoading: false,
    error: '',
  },
  groupFollowers: [],
  groupId: null,
});
