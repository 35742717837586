import EXPORT_INITIAL_STATE from './export.initial-state';
import EXPORT_MUTATIONS from './export.mutations';
import EXPORT_GETTERS from './export.getters';
import EXPORT_ACTIONS from './export.actions';

/* ==========================================================================
   EXPORTs MODULE STORE
   ========================================================================== */
const exportStore = {
  name: 'export',
  namespaced: true,
  state: () => EXPORT_INITIAL_STATE(),
  mutations: EXPORT_MUTATIONS,
  actions: EXPORT_ACTIONS,
  getters: EXPORT_GETTERS,
};

// eslint-disable-next-line import/prefer-default-export
export { exportStore };
