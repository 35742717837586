import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';
import { FilterHead } from '@/components';

export default {
  name: 'FilterDate',
  components: {
    [FilterHead.name]: FilterHead,
    DatePicker,
  },
  props: {},
  data: () => ({
    firstLoad: true,
    filterHead: {
      icon: 'calendar',
      text: 'Datum',
    },
    filter: {
      isOpen: false,
      showStarted: false,
      date: {
        from: null,
        to: null,
      },
    },
  }),
  computed: {
    ...mapGetters('event', {
      getEventFilter: 'getEventFilter',
    }),
    showStarted: {
      get() {
        return this.filter.showStarted;
      },
      set(value) {
        this.filter.showStarted = value;
        this.setEventShowStarted(value);
      },
    },
    formatDate: {
      get() {
        if (this.getEventFilter.date[0] == null) {
          this.filter.date.from = new Date();
        } else if (new Date(this.getEventFilter.date[0]) !== this.filter[0]) {
          this.filter.date.to = new Date(this.getEventFilter.date[0]);
        }

        if (this.getEventFilter.date[1] == null) {
          this.filter.date.to = new Date();
        } else if (new Date(this.getEventFilter.date[1]) !== this.filter[1]) {
          this.filter.date.to = new Date(this.getEventFilter.date[1]);
        }
        return this.filter.date;
      },
      set(value) {
        if (value.start === undefined && value.end === undefined) {
          this.filter.date.from = value.start;
          this.filter.date.to = value.end;
        } else {
          this.filter.date.from = value.start;
          this.filter.date.to = value.end;

          let dateFrom = moment(value.start).utc(true).toDate().toISOString()
            .substr(0, 10);
          if (dateFrom === new Date(null).toISOString().substr(0, 10)) {
            dateFrom = null;
          }
          let dateTo = moment(value.end).utc(true).toDate().toISOString()
            .substr(0, 10);
          if (dateTo === new Date(null).toISOString().substr(0, 10)) {
            dateTo = null;
          }

          const date = [dateFrom, dateTo];
          this.setEventFilterDate(date);
        }
      },
    },
  },
  methods: {
    ...mapMutations('event', {
      setEventFilterDate: 'SET_EVENT_FILTER_DATE',
      setEventShowStarted: 'SET_EVENT_FILTER_SHOW_STARTED',
    }),
    openFilter() {
      this.filter.isOpen = !this.filter.isOpen;
    },
  },
  watch: {
    getEventFilter: {
      handler(updatedEventFilter) {
        if (this.firstLoad && updatedEventFilter.date[0]) {
          this.$set(this.filter, 'date',
            {
              start: moment(this.getEventFilter.date[0], 'YYYY-MM-DD').toDate(),
              end: moment(this.getEventFilter.date[1], 'YYYY-MM-DD').toDate(),
            });
        }
        this.firstLoad = false;
        if (updatedEventFilter.date.length === 0) {
          this.$set(this.filter, 'date',
            {
              start: this.getEventFilter.date[0],
              end: this.getEventFilter.date[1],
            });
        }
        this.$set(this.filter, 'showStarted', this.getEventFilter.showStarted);
      },
      deep: true,
    },
  },
};
