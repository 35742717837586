import DISCIPLINE_INITIAL_STATE from './discipline.initial-state';
import DISCIPLINE_MUTATIONS from './discipline.mutations';
import DISCIPLINE_GETTERS from './discipline.getters';
import DISCIPLINE_ACTIONS from './discipline.actions';

/* ==========================================================================
   DISCIPLINE MODULE STORE
   ========================================================================== */
const disciplineStore = {
  name: 'discipline',
  namespaced: true,
  state: () => DISCIPLINE_INITIAL_STATE(),
  mutations: DISCIPLINE_MUTATIONS,
  actions: DISCIPLINE_ACTIONS,
  getters: DISCIPLINE_GETTERS,
};

// eslint-disable-next-line import/prefer-default-export
export { disciplineStore };
