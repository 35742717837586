import CHALLENGE_MUTATION_TYPES from './challenge.mutation-types';
import initialState from './challenge.initial-state';

const CHALLENGE_MUTATIONS = {
  [CHALLENGE_MUTATION_TYPES.SET_CHALLENGE_LIST](state, challenges) {
    state.challenges = challenges;
  },
  [CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE](state, isLoading) {
    state.status.loading = isLoading;
  },
  [CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE](state, isLoading) {
    state.buttonLoading = isLoading;
  },
  [CHALLENGE_MUTATION_TYPES.SET_ERROR](state, error) {
    state.status.loading = false;
    state.status.error = error;
  },
  [CHALLENGE_MUTATION_TYPES.SET_CHALLENGE](state, challenge) {
    state.challenge = challenge;
  },
  [CHALLENGE_MUTATION_TYPES.CLEAR_RANKING_LIST](state) {
    state.ranking = initialState().ranking;
  },
  [CHALLENGE_MUTATION_TYPES.APPEND_RANKING_LIST](state, ranking) {
    state.ranking.currentPage = ranking.currentPage;
    state.ranking.pageCount = ranking.pageCount;
    state.ranking.rowCount = ranking.rowCount;
    state.ranking.firstRowOnPage = ranking.firstRowOnPage;
    state.ranking.lastRowOnPage = ranking.lastRowOnPage;
    state.ranking.results.push(...ranking.results);
    if (ranking.currentPage === 1) state.userRank = ranking.results.splice(0, 1);
  },
};

export default CHALLENGE_MUTATIONS;
