export default () => ({
  event: {
    disciplines: [],
  },
  events: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
  userEvents: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
  buttonLoading: false,
  participants: {
    activeParticipants: {
      results: [],
    },
    inActiveParticipants: {
      results: [],
    },
  },
  eventTypes: [],
  markers: [],
  orderPrice: null,
  orderStatus: {
    id: null,
    paymentStatus: null,
    paymentMethod: null,
  },
  eventFilter: {
    age: [],
    price: [],
    date: [],
    tags: [],
    availablePlaces: [],
    query: '',
    type: '',
    pageSize: 9,
    discipline: null,
    location: null,
    showPrivate: null,
    showStarted: null,
  },
  userEventFilter: {
    page: 1,
    pageSize: 3,
  },
  page: 1,
  status: {
    loading: false,
    participantsLoading: false,
    registerParticipantsLoading: false,
    error: '',
  },
  featuredEvents: [],
});
