import CLUB_INITIAL_STATE from './club.initial-state';
import CLUB_MUTATIONS from './club.mutations';
import CLUB_GETTERS from './club.getters';
import CLUB_ACTIONS from './club.actions';

/* ==========================================================================
   CLUB MODULE STORE
   ========================================================================== */
const clubStore = {
  name: 'club',
  namespaced: true,
  state: () => CLUB_INITIAL_STATE(),
  mutations: CLUB_MUTATIONS,
  actions: CLUB_ACTIONS,
  getters: CLUB_GETTERS,
};

// eslint-disable-next-line import/prefer-default-export
export { clubStore };
