import TAG_INITITAL_STATE from './tag.initial-state';
import TAG_MUTATIONS from './tag.mutations';
import TAG_GETTERS from './tag.getters';
import TAG_ACTIONS from './tag.actions';

/* ==========================================================================
   TAG MODULE STORE
   ========================================================================== */
const tagStore = {
  name: 'tag',
  namespaced: true,
  state: () => TAG_INITITAL_STATE(),
  mutations: TAG_MUTATIONS,
  actions: TAG_ACTIONS,
  getters: TAG_GETTERS,
};

// eslint-disable-next-line import/prefer-default-export
export { tagStore };
