import { mapGetters, mapActions } from 'vuex';

import {
  Activity,
} from '@/components';

export default {
  name: 'ActivitiesRow',
  components: {
    [Activity.name]: Activity,
  },
  props: {},
  data: () => ({

  }),
  computed: {
    ...mapGetters('event', {
      events: 'getFeaturedEvents',
    }),
  },
  methods: {
    ...mapActions('event', {
      fetchFeaturedEvents: 'FETCH_DATA_FEATURED_EVENTS',
    }),
  },
  created() {
    this.fetchFeaturedEvents();
  },
};
