export default {
  name: 'Advertisement',
  components: {},
  props: {
    advertisement: null,
  },
  data: () => ({}),
  computed: {},
  methods: {
    openAdvertisement() {
      window.open(this.advertisement.link);
    },
  },
};
