export default {
  name: 'Loader',
  components: {},
  props: ['isLoading', 'color'],
  data: () => ({
  }),
  computed: {},
  methods: {
  },
};
