/* eslint-disable no-plusplus */
const GenerateYearArray = () => {
  const startYear = 2019;
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
};

const yearArray = GenerateYearArray();

// eslint-disable-next-line import/prefer-default-export
export { yearArray };
