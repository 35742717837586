import {
  FilterDate,
  FilterAge,
  FilterAvailablePlaces,
  FilterPrice,
  FilterTags,
  FilterType,
  FilterDiscipline,
  FilterLocation,
} from '@/components';

export default {
  name: 'SearchFilters',
  components: {
    [FilterDate.name]: FilterDate,
    [FilterAge.name]: FilterAge,
    [FilterAvailablePlaces.name]: FilterAvailablePlaces,
    [FilterPrice.name]: FilterPrice,
    [FilterTags.name]: FilterTags,
    [FilterType.name]: FilterType,
    [FilterDiscipline.name]: FilterDiscipline,
    [FilterLocation.name]: FilterLocation,
  },
  props: {
    showMobileFilter: false,
    isClubSearch: false,
    isGroupSearch: false,
  },
  data: () => ({
  }),
  computed: {},
  methods: {},
};
