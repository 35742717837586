/* eslint-disable no-unused-vars */

import { api } from '@/utils';
import EVENT_MUTATION_TYPES from './event.mutation-types';

const EVENT_ACTIONS = {
  [EVENT_MUTATION_TYPES.FETCH_DATA_EVENT_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(EVENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(EVENT_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('event/get', params)
        .then((response) => {
          commit(EVENT_MUTATION_TYPES.APPEND_EVENT_LIST, response.data);
          commit(EVENT_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(EVENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        }).catch((error) => {
          console.log(error);
          commit(EVENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(EVENT_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [EVENT_MUTATION_TYPES.FETCH_DATA_USER_EVENT_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(EVENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(EVENT_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('user/events', params)
        .then((response) => {
          commit(EVENT_MUTATION_TYPES.APPEND_USER_EVENT_LIST, response.data);
          commit(EVENT_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(EVENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        }).catch((error) => {
          commit(EVENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(EVENT_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [EVENT_MUTATION_TYPES.FETCH_DATA_EVENT_MARKERS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(EVENT_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.post('event/get', params)
        .then((response) => {
          commit(EVENT_MUTATION_TYPES.SET_EVENT_MARKERS, response.data);
          commit(EVENT_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        }).catch((error) => {
          console.log(error);
          commit(EVENT_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [EVENT_MUTATION_TYPES.FETCH_DATA_EVENT]({ commit }, url) {
    return new Promise((resolve, reject) => {
      commit(EVENT_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get(`event/${url}`)
        .then((response) => {
          commit(EVENT_MUTATION_TYPES.SET_EVENT, response.data);
          commit(EVENT_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(EVENT_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [EVENT_MUTATION_TYPES.FETCH_DATA_EDIT_EVENT]({ commit }, url) {
    return new Promise((resolve, reject) => {
      commit(EVENT_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get(`event/edit/${url}`)
        .then((response) => {
          commit(EVENT_MUTATION_TYPES.SET_EVENT, response.data);
          commit(EVENT_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(EVENT_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [EVENT_MUTATION_TYPES.FETCH_DATA_EVENT_PARTICIPANTS_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(EVENT_MUTATION_TYPES.SET_PARTICIPANTS_LOADING_STATE, true);
      let url = '';
      if (params.isAdmin) {
        url = `event/${params.id}/admin/participants?`;
      } else {
        url = `event/${params.id}/participants?`;
      }
      if (params.page) {
        url += `page=${params.page}&`;
      }
      if (params.pageSize) {
        url += `pageSize=${params.pageSize}`;
      }
      var model = { search: params.search, searchPast: params.searchPast };
      api.get(url, { params: model }).then((response) => {
        const participantsList = response.data;
        commit(EVENT_MUTATION_TYPES.APPEND_EVENT_PARTICIPANTS_LIST, participantsList);
        commit(EVENT_MUTATION_TYPES.SET_PARTICIPANTS_LOADING_STATE, false);
        resolve(response.data);
      }).catch((error) => {
        commit(EVENT_MUTATION_TYPES.SET_PARTICIPANTS_LOADING_STATE, false);
        commit(EVENT_MUTATION_TYPES.SET_ERROR, error);
        reject(error);
      });
    });
  },
  [EVENT_MUTATION_TYPES.REGISTER_PARTICIPANTS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      params.participantsArray.forEach((participant) => {
        var answers = [];
        var answerQuestionIds = Object.keys(participant.answers);
        answerQuestionIds.forEach((key) => {
          answers.push({ eventQuestionId: key, answer: participant.answers[key].answer });
        });
        // eslint-disable-next-line no-param-reassign
        participant.answers = answers;
      });

      commit(EVENT_MUTATION_TYPES.SET_REGISTER_PARTICIPANTS_LOADING_STATE, true);
      api.post(`event/${params.id}/register`, params.participantsArray).then((response) => {
        commit(EVENT_MUTATION_TYPES.SET_REGISTER_PARTICIPANTS_LOADING_STATE, false);
        resolve(response);
      }).catch((error) => {
        commit(EVENT_MUTATION_TYPES.SET_ERROR, error);
        commit(EVENT_MUTATION_TYPES.SET_REGISTER_PARTICIPANTS_LOADING_STATE, false);
        reject(error);
      });
    });
  },
  [EVENT_MUTATION_TYPES.FETCH_DATA_EVENT_TYPES]({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('event/types/all').then((response) => {
        commit(EVENT_MUTATION_TYPES.SET_EVENT_TYPES, response.data);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [EVENT_MUTATION_TYPES.CREATE_NEW_EVENT]({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const formData = new FormData();
      formData.append('Name', params.name);
      formData.append('Description', params.description);
      formData.append('Instructions', params.instructions);
      formData.append('Price', params.price);
      if (params.discount !== undefined) {
        formData.append('Discount', params.discount);
      }
      formData.append('FromAge', params.fromAge);
      formData.append('ToAge', params.toAge);
      formData.append('Address_Street', params.address_Street);
      formData.append('Address_Nr', params.address_Nr);
      formData.append('Address_Postcode', params.address_Postcode);
      formData.append('Address_City', params.address_City);
      formData.append('Address_Country', params.address_Country);
      formData.append('Address_Province', params.address_Province);
      formData.append('LocationName', params.locationName);
      formData.append('Lat', params.lat);
      formData.append('Long', params.long);
      formData.append('MaxAmountOfParticipants', params.maxAmountOfParticipants);
      formData.append('From', params.from);
      formData.append('Until', params.until);

      params.disciplineIds.forEach((dis) => {
        formData.append('DisciplineIds', dis);
      });

      formData.append('EventUrl', params.eventUrl);
      formData.append('ClubURL', params.clubUrl);
      formData.append('ContactEmail', params.contactEmail);
      if (params.externalSubscribingInstructions !== undefined) {
        formData.append('ExternalSubscribingInstructions', params.externalSubscribingInstructions);
      }
      formData.append('EventTypeId', params.eventTypeId);
      formData.append('Image', params.image);
      formData.append('RequiredLicenseNumber', params.requiredLicenseNumber);

      formData.append('IsPrivate', params.isPrivate);
      if (params.registrationPossibleUntil) {
        formData.append('RegistrationPossibleUntil', params.registrationPossibleUntil);
      }


      let i = 0;
      if (params.tags !== undefined) {
        params.tags.forEach((tag) => {
          formData.append(`Tags[${i}]`, tag.text);
          i += 1;
        });
      }

      let index = 0;
      if (params.questions !== undefined) {
        params.questions.forEach((question) => {
          formData.append(`Questions[${index}].question`, question.question);
          formData.append(`Questions[${index}].isRequired`, question.isRequired);

          index += 1;
        });
      }

      api.post('event', formData, config)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [EVENT_MUTATION_TYPES.UPDATE_EVENT]({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const formData = new FormData();
      formData.append('Name', params.name);
      formData.append('Description', params.description);
      formData.append('Instructions', params.instructions);
      formData.append('Price', params.price);
      if (params.discount !== undefined) {
        formData.append('Discount', params.discount);
      }
      formData.append('FromAge', params.fromAge);
      formData.append('ToAge', params.toAge);
      formData.append('Address_Street', params.address_Street);
      formData.append('Address_Nr', params.address_Nr);
      formData.append('Address_Postcode', params.address_Postcode);
      formData.append('Address_City', params.address_City);
      formData.append('Address_Country', params.address_Country);
      formData.append('Address_Province', params.address_Province);
      formData.append('LocationName', params.locationName);
      formData.append('Lat', params.lat);
      formData.append('Long', params.long);
      formData.append('MaxAmountOfParticipants', params.maxAmountOfParticipants);
      formData.append('From', params.from);
      formData.append('Until', params.until);

      params.disciplineIds.forEach((dis) => {
        formData.append('DisciplineIds', dis);
      });

      formData.append('ClubId', params.clubId);
      formData.append('ContactEmail', params.contactEmail);
      formData.append('EventUrl', params.eventUrl);
      if (params.externalSubscribingInstructions !== undefined) {
        formData.append('ExternalSubscribingInstructions', params.externalSubscribingInstructions);
      }
      formData.append('EventTypeId', params.eventTypeId);
      formData.append('Image', params.image);
      formData.append('RequiredLicenseNumber', params.requiredLicenseNumber);

      formData.append('IsPrivate', params.isPrivate);
      if (params.registrationPossibleUntil) {
        formData.append('RegistrationPossibleUntil', params.registrationPossibleUntil);
      }

      let i = 0;
      params.tags.forEach((tag) => {
        formData.append(`Tags[${i}]`, tag.text);
        i += 1;
      });

      let index = 0;
      if (params.questions !== undefined) {
        params.questions.forEach((question) => {
          formData.append(`Questions[${index}].question`, question.question);
          formData.append(`Questions[${index}].isRequired`, question.isRequired);

          index += 1;
        });
      }
      api.put(`event/${params.id}`, formData, config)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [EVENT_MUTATION_TYPES.UPDATE_EVENT_PARTICIPANT]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.put(`event/${params.eventId}/updateparticipant`, params).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [EVENT_MUTATION_TYPES.DELETE_EVENT]({ commit }, id) {
    const event = { eventId: id };
    return new Promise((resolve, reject) => {
      api.post('event/deleteevent', event).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [EVENT_MUTATION_TYPES.REMOVE_PARTICIPANT]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.post('event/removeParticipant', params).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [EVENT_MUTATION_TYPES.FETCH_DATA_EVENT_ORDER_PRICE]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.get(`event/${params.eventId}/price?numberOfParticipants=${params.numberOfParticipants}&validLicenseNumbers=${params.validLicenseNumberCount}`).then((response) => {
        commit(EVENT_MUTATION_TYPES.SET_EVENT_ORDER_PRICE, response.data);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [EVENT_MUTATION_TYPES.FETCH_DATA_EVENT_ORDER_STATUS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.get(`event/${params.eventId}/order/${params.orderId}`).then((response) => {
        commit(EVENT_MUTATION_TYPES.SET_EVENT_ORDER_STATUS, response.data);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [EVENT_MUTATION_TYPES.CREATE_PARTICIPANTS_CSV]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.get(`event/${params.eventId}/csv`).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  [EVENT_MUTATION_TYPES.CREATE_CANCELLED_PARTICIPANTS_CSV]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.get(`event/${params.eventId}/csv/cancelled`).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  [EVENT_MUTATION_TYPES.FETCH_DATA_FEATURED_EVENTS]({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('event/featured').then((response) => {
        commit(EVENT_MUTATION_TYPES.SET_FEATURED_EVENTS, response.data);
        resolve(response);
      }).catch((error) => reject(error));
    });
  },
};

export default EVENT_ACTIONS;
