import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'OrderStatusModal',
  components: {
  },
  props: {
    showStatusModal: false,
    event: null,
    orderId: null,
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters('event', {
      order: 'getOrderStatus',
    }),
  },
  methods: {
    ...mapActions('event', {
      fetchOrderStatus: 'FETCH_DATA_EVENT_ORDER_STATUS',
    }),

    closeModal() {
      this.$emit('closeModal');
    },
  },
  created() {
    if (!this.showStatusModal) {
      return;
    }

    const paramObject = {
      eventId: this.event.id,
      orderId: this.orderId,
    };

    this.fetchOrderStatus(paramObject);
  },
};
