import {
  mapMutations,
} from 'vuex';
import NavigationProfile from '../NavigationProfile/NavigationProfile.vue';

export default {
  name: 'Flyout',
  components: {
    [NavigationProfile.name]: NavigationProfile,
  },
  props: {
    showFlyout: false,
    toggleFlyout: '',
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
    ...mapMutations('club', {
      clearClubFilterQuery: 'CLEAR_CLUB_FILTER_QUERY',
    }),
    ...mapMutations('group', {
      clearGroupFilterQuery: 'CLEAR_GROUP_FILTER_QUERY',
    }),
    ...mapMutations('event', {
      clearEventFilterQuery: 'CLEAR_EVENT_FILTER_QUERY',
    }),
  },
};
