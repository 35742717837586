import { mapGetters, mapMutations } from 'vuex';
import { provinceArray } from '@/utils';
import { FilterHead } from '@/components';

export default {
  name: 'FilterLocation',
  components: {
    [FilterHead.name]: FilterHead,
  },
  props: { isClubSearch: false, isGroupSearch: false },
  data: () => ({
    filterHead: {
      icon: 'location-arrow',
      text: 'Locatie',
    },
    filter: {
      isOpen: false,
      location: '',
    },
    locations: provinceArray,
  }),
  computed: {
    ...mapGetters('event', {
      getEventFilter: 'getEventFilter',
    }),
    ...mapGetters('club', {
      getClubFilter: 'getClubFilter',
    }),
  },
  methods: {
    ...mapMutations('event', {
      setEventFilterLocation: 'SET_EVENT_FILTER_LOCATION',
    }),
    ...mapMutations('club', {
      setClubFilterLocation: 'SET_CLUB_FILTER_LOCATION',
    }),
    ...mapMutations('group', {
      setGroupFilterLocation: 'SET_GROUP_FILTER_LOCATION',
    }),
    openFilter() {
      this.filter.isOpen = !this.filter.isOpen;
    },
    updateFilterLocation(location) {
      if (this.filter.location === location) {
        this.filter.location = null;
      } else {
        this.filter.location = location;
      }

      if (this.isClubSearch) {
        this.setClubFilterLocation(this.filter.location);
      } else if (this.isGroupSearch) {
        this.setGroupFilterLocation(this.filter.location);
      } else {
        this.setEventFilterLocation(this.filter.location);
      }

      this.openFilter();
    },
  },
  watch: {
    getEventFilter: {
      handler() {
        this.filter.location = this.getEventFilter.location;

        if (this.getEventFilter.location === 0 || this.getEventFilter.location === null) {
          this.filter.location = null;
        }
      },
      deep: true,
    },
    getClubFilter: {
      handler() {
        this.filter.location = this.getClubFilter.location;

        if (this.getClubFilter.location === 0 || this.getClubFilter.location === null) {
          this.filter.location = null;
        }
      },
      deep: true,
    },
    getGroupFilter: {
      handler() {
        this.filter.location = this.getGroupFilter.location;

        if (this.getGroupFilter.location === 0 || this.getGroupFilter.location === null) {
          this.filter.location = null;
        }
      },
      deep: true,
    },

  },
};
