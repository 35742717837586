import storageMock from '../storageMock';

export default {
  getItem(key) {
    if (typeof (localStorage) === 'undefined') return storageMock.getItem(key);
    return localStorage.getItem(key);
  },
  setItem(key, value) {
    if (typeof (localStorage) === 'undefined') storageMock.setItem(key, value);
    else localStorage.setItem(key, value);
  },
  removeItem(key) {
    if (typeof (localStorage) === 'undefined') storageMock.removeItem(key);
    else localStorage.removeItem(key);
  },
};
