/* eslint-disable no-undef */

// import {
//   LMap, LTileLayer, LMarker,
// } from 'vue2-leaflet';
// eslint-disable-next-line no-unused-vars
// import { GestureHandling } from 'leaflet-gesture-handling';
import { Activity, ClubCard, GroupCard } from '@/components';
import isServer from '@/utils/isServer';

if (!isServer) {
  var { LMap, LTileLayer, LMarker } = require('vue2-leaflet');
  var markerCluster = require('vue2-leaflet-markercluster');
}
export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    'l-marker-cluster': markerCluster,
    Activity,
    ClubCard,
    GroupCard,
  },
  props: {
    zoom: null,
    markers: null,
    center: null,
    showEvents: false,
    showGroups: false,
    markersClickable: false,
  },
  data: () => ({
    // this is the provider for the map, this decides how the map will look
    url: 'https://a.tile.openstreetmap.de/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    activityCardIsActive: false,
    clubCardIsActive: false,
    groupCardIsActive: false,
    popUpStyle: {
      top: `${1}px`,
      left: `${1}px`,
      mapSize: {
        width: 0,
        height: 0,
      },
    },
    event: {},
    group: {},
    window: {
      width: 0,
      height: 0,
    },
    mapOptions: {
      gestureHandling: true,
    },
  }),
  computed: {
  },
  methods: {
    markerClick(clickEvent, markerPopupContent) {
      this.popUpStyle.top = `${clickEvent.containerPoint.y}px`;
      this.popUpStyle.left = `${clickEvent.containerPoint.x}px`;

      // eslint-disable-next-line no-underscore-dangle
      this.popUpStyle.mapSize.width = clickEvent.target._map._size.x;
      // eslint-disable-next-line no-underscore-dangle
      this.popUpStyle.mapSize.height = clickEvent.target._map._size.y;
      if (this.showEvents) {
        this.displayEventActivityCard(markerPopupContent);
      } else if (this.showGroups) {
        this.displayGroupCard(markerPopupContent);
      } else {
        this.displayClubCard(markerPopupContent);
      }
    },
    displayEventActivityCard(markerPopupContent) {
      this.event = markerPopupContent;
      this.activityCardIsActive = true;
      this.clubCardIsActive = false;
    },
    displayClubCard(markerPopupContent) {
      this.activityCardIsActive = false;
      this.club = markerPopupContent;
      this.clubCardIsActive = true;
    },
    displayGroupCard(markerPopupContent) {
      this.activityCardIsActive = false;
      this.group = markerPopupContent;
      this.groupCardIsActive = true;
    },
    clearPopup() {
      this.activityCardIsActive = false;
      this.clubCardIsActive = false;
      this.groupCardIsActive = false;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
};
