import EXPORT_MUTATION_TYPES from './export.mutation-types';

const EXPORT_MUTATIONS = {
  [EXPORT_MUTATION_TYPES.SET_ERROR](state, error) {
    state.buttonLoading = false;
    state.error = error;
  },
  [EXPORT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE](state, status) {
    state.buttonLoading = status;
  },
};

export default EXPORT_MUTATIONS;
