import ADVERTISEMENT_MUTATION_TYPES from './advertisement.mutation-types';
import initialState from './advertisement.initial-state';

const ADVERTISEMENT_MUTATIONS = {
  [ADVERTISEMENT_MUTATION_TYPES.SET_ADVERTISEMENT_LIST](state, advertisements) {
    state.advertisements.currentPage = advertisements.currentPage;
    state.advertisements.pageCount = advertisements.pageCount;
    state.advertisements.rowCount = advertisements.rowCount;
    state.advertisements.firstRowOnPage = advertisements.firstRowOnPage;
    state.advertisements.lastRowOnPage = advertisements.lastRowOnPage;
    state.advertisements.results = advertisements.results;
  },
  [ADVERTISEMENT_MUTATION_TYPES.CLEAR_ADVERTISEMENT_LIST](state) {
    state.advertisements = initialState().advertisements;
  },
  [ADVERTISEMENT_MUTATION_TYPES.SET_ADVERTISEMENT](state, advertisement) {
    state.advertisement = advertisement;
  },
  [ADVERTISEMENT_MUTATION_TYPES.SET_ADVERTISEMENT_BESTMATCH](state, advertisement) {
    state.advertisementBestmatch = advertisement;
  },
  [ADVERTISEMENT_MUTATION_TYPES.SET_ERROR](state, error) {
    state.status.loading = false;
    state.error = error;
  },
  [ADVERTISEMENT_MUTATION_TYPES.SET_LOADING_STATE](state, isLoading) {
    state.status.loading = isLoading;
  },
  [ADVERTISEMENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE](state, status) {
    state.buttonLoading = status;
  },
};

export default ADVERTISEMENT_MUTATIONS;
