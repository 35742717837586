const ACCOUNT_GETTERS = {
  getToken: (state) => state.token,
  getServerToken: (state) => state.serverToken,
  getCurrentUser: (state) => state.currentUser,
  getServerUser: (state) => state.serverUser,
  getLoadingState: (state) => state.status.loading,
  getLoadingStateResetPassword: (state) => state.status.resetPasswordLoading,
  getLoadingStateForgotPassword: (state) => state.status.forgotPasswordLoading,
};

export default ACCOUNT_GETTERS;
