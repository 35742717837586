const provinceArray = ['West - Vlaanderen', 'Oost - Vlaanderen', 'Antwerpen', 'Limburg', 'Vlaams - Brabant', 'Waals - Brabant', 'Namen', 'Henegouwen', 'Luik', 'Luxemburg'];
const countryArray = ['België', 'Nederland', 'Frankrijk', 'Duitsland', 'Luxemburg'];
// eslint-disable-next-line import/prefer-default-export

const roundOffCoordinate = (value, precision) => {
  const power = 10 ** (precision || 0);
  return String(Math.round(value * power) / power);
};

export { provinceArray, countryArray, roundOffCoordinate };
