import {
  mapGetters,
} from 'vuex';

export default {
  name: 'GroupCard',
  components: {},
  props: {
    group: {},
    popUpStyle: {},
  },
  data: () => ({
    mobileMap: false,
    error: false,
  }),
  computed: {
    ...mapGetters('group', {
      groups: 'getGroupList',
    }),
    cardStyle() {
      if (typeof this.popUpStyle !== 'undefined') {
        return this.showPopupInMap(this.$el, this.popUpStyle);
      }
      return {};
    },
  },
  methods: {
    openMiddleClickEvent() {
      const routeData = this.$router.resolve({ name: 'group-detail', params: { groupurl: encodeURIComponent(this.group.groupUrl) } });
      window.open(routeData.href, '_blank');
    },
    showPopupInMap() {
      const popUpTop = this.popUpStyle.top.split('.')[0].replace(/[^0-9]/g, '');
      const popUpLeft = this.popUpStyle.left.split('.')[0].replace(/[^0-9]/g, '');

      const { mapWidth, mapHeight, windowWidth } = this.popUpStyle;

      if (windowWidth <= 480) {
        this.mobileMap = true;
        return {
          position: 'fixed', bottom: '1rem', left: '1.5rem', width: '92%',
        };
      }

      if (windowWidth <= 768) {
        this.mobileMap = true;
        return {
          position: 'fixed', bottom: '1rem', right: '1.5rem', width: '50%',
        };
      }

      const longEnough = mapHeight - popUpTop - 130 > 0;
      const bigEnough = mapWidth - popUpLeft - 300 > 0;

      if (longEnough && bigEnough) {
        return { top: this.popUpStyle.top, left: this.popUpStyle.left };
      }
      if (!longEnough && bigEnough) {
        return { bottom: `${mapHeight - popUpTop}px`, left: this.popUpStyle.left };
      }
      if (longEnough && !bigEnough) {
        return { top: this.popUpStyle.top, right: `${mapWidth - popUpLeft}px` };
      }

      return { bottom: `${mapHeight - popUpTop}px`, right: `${mapWidth - popUpLeft}px` };
    },

    clearPopup(event) {
      if (event) {
        event.preventDefault();
      }
      this.$emit('closePopup');
    },

    clickOutside(event) {
      if (this.mobileMap) {
        this.clearPopup(event);
      }
    },
  },
  created() {
  },
};
