import { mapGetters, mapActions, mapMutations } from 'vuex';
import { FilterHead } from '@/components';
import isServer from '@/utils/isServer';

if (!isServer) {
  var VueTagsInput = require('@johmun/vue-tags-input').default;
}
export default {
  name: 'FilterTags',
  components: {
    [FilterHead.name]: FilterHead,
    VueTagsInput,
  },
  props: {},
  data: () => ({
    filterHead: {
      icon: 'tag',
      text: 'Tag',
    },
    filter: {
      isOpen: false,
      tag: '',
      tags: [],
    },
    tags: [],
  }),
  computed: {
    ...mapGetters('tag', {
      tagsList: 'getTagList',
    }),
    filteredItems() {
      return this.tagsList.filter(
        (i) => i.text.toLowerCase().indexOf(this.currentTag.toLowerCase()) !== -1,
      );
    },
    ...mapGetters('event', {
      getEventFilter: 'getEventFilter',
    }),
    currentTag: {
      get() {
        return this.filter.tag;
      },
      set(value) {
        this.filter.tag = value;
      },
    },
  },
  methods: {
    ...mapActions('tag', {
      initialiseTags: 'FETCH_DATA_TAG_LIST',
    }),
    ...mapMutations('event', {
      setEventFilterTags: 'SET_EVENT_FILTER_TAGS',
    }),
    openFilter() {
      this.filter.isOpen = !this.filter.isOpen;
      // this.enableDisableTagInput();
    },
    tagsChanged(tags) {
      this.tags = tags.map((t) => ({ text: t.text }));

      this.filter.tags = [];
      this.tags.forEach((tag) => {
        this.filter.tags.push(tag.text);
      });

      // this.enableDisableTagInput();

      this.setEventFilterTags(this.filter.tags);
    },
    // this makes sure that the user can't add anymore tags after the max amount of tags are reached
    enableDisableTagInput() {
      this.$nextTick(() => {
        const autoCompleteDropdown = document.getElementsByClassName('ti-autocomplete')[0];
        const tagInput = document.getElementsByClassName('ti-new-tag-input')[0];
        if (typeof tagInput === 'undefined') return;
        if (this.tags.length === 1) {
          tagInput.style.display = 'none';
          if (typeof autoCompleteDropdown !== 'undefined') autoCompleteDropdown.style.display = 'none';
        } else {
          tagInput.style.display = 'block';
          if (typeof autoCompleteDropdown !== 'undefined') autoCompleteDropdown.style.display = 'block';
          this.disableInput = false;
        }
      });
    },
  },
  watch: {
    getEventFilter: {
      handler() {
        this.tags = this.getEventFilter.tags.map((t) => ({ text: t }));
        // this.enableDisableTagInput();
      },
      deep: true,
    },
  },
  created() {
    this.tags = this.getEventFilter.tags.map((t) => ({ text: t }));
    this.initialiseTags();
  },
};
