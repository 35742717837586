const CLUB_MUTATION_TYPES = Object.freeze({
  FETCH_DATA_CLUB_LIST: 'FETCH_DATA_CLUB_LIST',
  FETCH_DATA_USER_CLUB_LIST: 'FETCH_DATA_USER_CLUB_LIST',
  FETCH_DATA_CLUB_FOLLOWERS_LIST: 'FETCH_DATA_CLUB_FOLLOWERS_LIST',
  FETCH_DATA_CLUB: 'FETCH_DATA_CLUB',
  FETCH_DATA_EDIT_CLUB: 'FETCH_DATA_EDIT_CLUB',
  FETCH_DATA_CLUB_MARKERS: 'FETCH_DATA_CLUB_MARKERS',
  FETCH_DATA_CLUB_EVENTS_LIST: 'FETCH_DATA_CLUB_EVENTS_LIST',
  FETCH_DATA_PAST_CLUB_EVENTS_LIST: 'FETCH_DATA_PAST_CLUB_EVENTS_LIST',

  FOLLOW_CLUB: 'FOLLOW_CLUB',
  UNFOLLOW_CLUB: 'UNFOLLOW_CLUB',

  ADD_CLUB_ADMIN: 'ADD_CLUB_ADMIN',
  REMOVE_CLUB_ADMIN: 'REMOVE_CLUB_ADMIN',
  REMOVE_CLUB_FOLLOWER: 'REMOVE_CLUB_FOLLOWER',

  SET_CLUB: 'SET_CLUB',
  SET_CLUB_FILTER_DISCIPLINE: 'SET_CLUB_FILTER_DISCIPLINE',
  SET_CLUB_FILTER_LOCATION: 'SET_CLUB_FILTER_LOCATION',
  SET_CLUB_FILTER_QUERY: 'SET_CLUB_FILTER_QUERY',
  CLEAR_CLUB_FILTER_QUERY: 'CLEAR_CLUB_FILTER_QUERY',
  SET_CLUB_ID: 'SET_CLUB_ID',
  SET_CLUB_MARKERS: 'SET_CLUB_MARKERS',
  CREATE_CLUB: 'CREATE_CLUB',
  EDIT_CLUB: 'EDIT_CLUB',
  CREATE_PARTICIPANTS_CSV: 'CREATE_PARTICIPANTS_CSV',

  APPEND_CLUB_LIST: 'APPEND_CLUB_LIST',
  APPEND_USER_CLUB_LIST: 'APPEND_USER_CLUB_LIST',
  APPEND_CLUB_FOLLOWERS_LIST: 'APPEND_CLUB_FOLLOWERS_LIST',
  APPEND_CLUB_EVENTS_LIST: 'APPEND_CLUB_EVENTS_LIST',
  APPEND_PAST_CLUB_EVENTS_LIST: 'APPEND_PAST_CLUB_EVENTS_LIST',

  CLEAR_CLUB_LIST: 'CLEAR_CLUB_LIST',
  CLEAR_USER_CLUB_LIST: 'CLEAR_USER_CLUB_LIST',
  CLEAR_CLUB_FOLLOWERS_LIST: 'CLEAR_CLUB_FOLLOWERS_LIST',
  CLEAR_CLUB_EVENTS_LIST: 'CLEAR_CLUB_EVENTS_LIST',
  CLEAR_PAST_CLUB_EVENTS_LIST: 'CLEAR_PAST_CLUB_EVENTS_LIST',

  SET_ERROR: 'SET_ERROR',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_BUTTON_LOADING_STATE: 'SET_BUTTON_LOADING_STATE',
  SET_FOLLOWERS_LOADING_STATE: 'SET_FOLLOWERS_LOADING_STATE',
  SET_CLUB_EVENTS_LOADING_STATE: 'SET_CLUB_EVENTS_LOADING_STATE',

  CREATE_FISCAL_CSV: 'CREATE_FISCAL_CSV',
});

export default CLUB_MUTATION_TYPES;
