import DISCIPLINE_MUTATION_TYPES from './discipline.mutation-types';

const DISCIPLINE_MUTATIONS = {

  [DISCIPLINE_MUTATION_TYPES.SET_DISCIPLINE_LIST](state, disciplines) {
    state.disciplines = disciplines;
  },
  [DISCIPLINE_MUTATION_TYPES.SET_LOADING_STATE](state, isLoading) {
    state.status.loading = isLoading;
  },
  [DISCIPLINE_MUTATION_TYPES.SET_ERROR](state, error) {
    state.status.loading = false;
    state.status.error = error;
  },

};

export default DISCIPLINE_MUTATIONS;
