/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-dynamic-require */

// import moment from 'moment';

export default {
  name: 'UserCard',
  components: {},
  props: {
    name: '',
    subtitle: '',
    profile: '',
  },
  data: () => ({
    error: false,
  }),
  computed: {
    formatedSubtitle() {
      // if (this.isValidDate(this.subtitle)) {
      //   return `Joined ${moment(this.subtitle).format('DD MMMM YYYY')}`;
      // }
      // return this.subtitle;
      return '';
    },
  },
  methods: {
    isValidDate(value) {
      const dateWrapper = new Date(value);
      return !isNaN(dateWrapper.getDate());
    },
  },
};
