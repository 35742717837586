import {
  mapGetters, mapMutations,
} from 'vuex';
import {
  Hamburger,
  Flyout,
} from '@/components';

export default {
  name: 'HeaderNavigation',
  components: {
    [Hamburger.name]: Hamburger,
    [Flyout.name]: Flyout,
  },
  props: {},
  data: () => ({
    showFlyout: false,
    error: false,
  }),
  computed: {
    isLoggedIn() {
      return this.$isLoggedIn;
    },
    ...mapGetters('account', {
      currentUser: 'getCurrentUser',
    }),
    isFrame() {
      return this.$route.name.includes('frame');
    },
  },
  methods: {
    ...mapMutations('club', {
      clearClubFilterQuery: 'CLEAR_CLUB_FILTER_QUERY',
    }),
    ...mapMutations('group', {
      clearGroupFilterQuery: 'CLEAR_GROUP_FILTER_QUERY',
    }),
    ...mapMutations('event', {
      clearEventFilterQuery: 'CLEAR_EVENT_FILTER_QUERY',
    }),
    toggleFlyout() {
      this.showFlyout = !this.showFlyout;
    },
  },
  created() {
  },
};
