const GROUP_GETTERS = {
  getGroup: (state) => state.group,
  getGroupList: (state) => state.groups,
  getUserGroupList: (state) => state.userGroups,
  getGroupMarkers: (state) => state.markers,
  getGroupFollowers: (state) => state.groupFollowers,
  getLoadingState: (state) => state.status.loading,
  getGroupFilter: (state) => state.groupFilter,
  getUserGroupFilter: (state) => state.userGroupFilter,
  getButtonLoadingState: (state) => state.buttonLoading,
  getGroupId: (state) => state.groupId,
};

export default GROUP_GETTERS;
