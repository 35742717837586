import EVENT_MUTATION_TYPES from './event.mutation-types';
import initialState from './event.initial-state';

const EVENT_MUTATIONS = {
  [EVENT_MUTATION_TYPES.APPEND_EVENT_LIST](state, events) {
    state.events.currentPage = events.currentPage;
    state.events.pageCount = events.pageCount;
    state.events.rowCount = events.rowCount;
    state.events.firstRowOnPage = events.firstRowOnPage;
    state.events.lastRowOnPage = events.lastRowOnPage;
    state.events.results.push(...events.results);
  },
  [EVENT_MUTATION_TYPES.APPEND_USER_EVENT_LIST](state, events) {
    state.userEvents.currentPage = events.currentPage;
    state.userEvents.pageCount = events.pageCount;
    state.userEvents.rowCount = events.rowCount;
    state.userEvents.firstRowOnPage = events.firstRowOnPage;
    state.userEvents.lastRowOnPage = events.lastRowOnPage;
    state.userEvents.results.push(...events.results);
  },
  [EVENT_MUTATION_TYPES.CLEAR_EVENT_LIST](state) {
    state.events = initialState().events;
  },
  [EVENT_MUTATION_TYPES.CLEAR_USER_EVENT_LIST](state) {
    state.userEvents = initialState().userEvents;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT](state, event) {
    state.event = event;
  },
  [EVENT_MUTATION_TYPES.APPEND_EVENT_PARTICIPANTS_LIST](state, participants) {
    state.participants = participants;
  },
  [EVENT_MUTATION_TYPES.CLEAR_EVENT_PARTICIPANTS_LIST](state) {
    state.participants = initialState().participants;
  },
  [EVENT_MUTATION_TYPES.SET_ERROR](state, error) {
    state.status.loading = false;
    state.status.error = error;
  },
  [EVENT_MUTATION_TYPES.SET_LOADING_STATE](state, isLoading) {
    state.status.loading = isLoading;
  },
  [EVENT_MUTATION_TYPES.SET_PARTICIPANTS_LOADING_STATE](state, isLoading) {
    state.status.participantsLoading = isLoading;
  },
  [EVENT_MUTATION_TYPES.SET_REGISTER_PARTICIPANTS_LOADING_STATE](state, isLoading) {
    state.status.registerParticipantsLoading = isLoading;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER](state, filter) {
    state.eventFilter = filter;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_DATE](state, filterDate) {
    state.eventFilter.date = filterDate;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_SHOW_STARTED](state, filterShowStarted) {
    state.eventFilter.showStarted = filterShowStarted;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_AGE](state, filterAge) {
    state.eventFilter.age = filterAge;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_SHOW_PRIVATE](state, filterShowPrivate) {
    state.eventFilter.showPrivate = filterShowPrivate;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_AVAILABLE_PLACES](state, filterAvailablePlaces) {
    state.eventFilter.availablePlaces = filterAvailablePlaces;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_PRICE](state, filterPrice) {
    state.eventFilter.price = filterPrice;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_TAGS](state, filterTags) {
    state.eventFilter.tags = filterTags;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_QUERY](state, filterQuery) {
    state.eventFilter.query = filterQuery;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_TYPES](state, types) {
    state.eventTypes = types;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_TYPE](state, type) {
    state.eventFilter.type = type;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_MARKERS](state, markers) {
    state.markers = markers;
  },
  [EVENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE](state, status) {
    state.buttonLoading = status;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_DISCIPLINE](state, filterDiscipline) {
    state.eventFilter.discipline = filterDiscipline;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_FILTER_LOCATION](state, filterLocation) {
    state.eventFilter.location = filterLocation;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_ORDER_PRICE](state, price) {
    state.orderPrice = price;
  },
  [EVENT_MUTATION_TYPES.SET_EVENT_ORDER_STATUS](state, status) {
    state.orderStatus = status;
  },
  [EVENT_MUTATION_TYPES.CLEAR_EVENT_FILTER_QUERY](state) {
    state.eventFilter = initialState().eventFilter;
  },
  [EVENT_MUTATION_TYPES.SET_FEATURED_EVENTS](state, featuredEvents) {
    state.featuredEvents = featuredEvents.results;
  },
};

export default EVENT_MUTATIONS;
