const EVENT_GETTERS = {
  getEventList: (state) => state.events,
  getUserEventList: (state) => state.userEvents,
  getEvent: (state) => state.event,
  getLoadingState: (state) => state.status.loading,
  getButtonLoadingState: (state) => state.buttonLoading,
  getParticipants: (state) => state.participants,
  getParticipantsLoadingState: (state) => state.status.participantsLoading,
  getRegisterParticipantsLoadingState: (state) => state.status.registerParticipantsLoading,
  getEventFilter: (state) => state.eventFilter,
  getUserEventFilter: (state) => state.userEventFilter,
  getEventTypes: (state) => state.eventTypes,
  getEventMarkers: (state) => state.markers,
  getOrderPrice: (state) => state.orderPrice,
  getOrderStatus: (state) => state.orderStatus,
  getFeaturedEvents: (state) => state.featuredEvents,
};

export default EVENT_GETTERS;
