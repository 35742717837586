const ADVERTISEMENT_MUTATION_TYPES = Object.freeze({
  FETCH_DATA_ADVERTISEMENT_LIST: 'FETCH_DATA_ADVERTISEMENT_LIST',
  FETCH_DATA_ADVERTISEMENT: 'FETCH_DATA_ADVERTISEMENT',
  FETCH_DATA_ADVERTISEMENT_BESTMATCH: 'FETCH_DATA_ADVERTISEMENT_BESTMATCH',

  CREATE_NEW_ADVERTISEMENT: 'CREATE_NEW_ADVERTISEMENT',
  UPDATE_ADVERTISEMENT: 'UPDATE_ADVERTISEMENT',
  DELETE_ADVERTISEMENT: 'DELETE_ADVERTISEMENT',

  SET_ADVERTISEMENT: 'SET_ADVERTISEMENT',
  SET_ADVERTISEMENT_BESTMATCH: 'SET_ADVERTISEMENT_BESTMATCH',
  SET_ADVERTISEMENT_LIST: 'SET_ADVERTISEMENT_LIST',

  CLEAR_ADVERTISEMENT_LIST: 'CLEAR_ADVERTISEMENT_LIST',

  SET_ERROR: 'SET_ERROR',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_BUTTON_LOADING_STATE: 'SET_BUTTON_LOADING_STATE',
});

export default ADVERTISEMENT_MUTATION_TYPES;
