/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import VueMeta from 'vue-meta';
import Router from 'vue-router';
import _ from 'lodash';
import storageMock from './utils/localStorageMock';
import isServer from './utils/isServer';
import { createStore } from './store';

const store = createStore();

Vue.use(Router);
Vue.use(VueMeta);

const checkAuth = () => {
  var expiry = '';
  if (isServer && store.state.account.serverToken !== '') {
    expiry = _.get(JSON.parse(store.state.account.serverToken), 'expiry', '');
  } else {
    expiry = _.get(JSON.parse(storageMock.getItem('token')), 'expiry', '');
  }
  if (expiry !== '') {
    if ((expiry - Date.now()) < 0) {
      store.dispatch('account/LOGOUT');
      return false;
    }
    return true;
  }
  return false;
};

const ifNotAuthenticated = (to, from, next) => {
  if (!checkAuth()) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (checkAuth()) {
    next();
    return;
  }
  next('/login');
};

const logOut = (to, from, next) => {
  if (checkAuth()) {
    store.dispatch('account/LOGOUT').then(() => {
      next('/login');
    });
    window.location.reload();
  }
};

export function createRouter() {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/',
        name: 'home',
        meta: {
          title: 'Home - IKwatersport',
        },
        component: () => import('./views/Home/Home.vue'),
      },
      {
        path: '/login',
        name: 'login',
        meta: {
          title: 'Inloggen - IKwatersport',
        },
        component: () => import('./views/Login/Login.vue'),
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: '/logout',
        name: 'logout',
        beforeEnter: logOut,
      },
      {
        path: '/register',
        name: 'register',
        meta: {
          title: 'Registreren - IKwatersport',
        },
        component: () => import('./views/Register/Register.vue'),
      },
      {
        path: '/profiel',
        name: 'profile',
        meta: {
          title: 'Profiel - IKwatersport',
        },
        component: () => import('./views/Profile/Profile.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/profiel/edit',
        name: 'profile-edit',
        meta: {
          title: 'Profiel - IKwatersport',
        },
        component: () => import('./views/EditProfile/EditProfile.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/advertenties',
        name: 'advertisements',
        meta: {
          title: 'Advertenties - IKwatersport',
        },
        component: () => import('./views/Advertisement/AdvertisementSearch/AdvertisementSearch.vue'),
      },
      {
        path: '/advertenties/create',
        name: 'advertisement-create',
        meta: {
          title: 'Advertentie aanmaken - IKwatersport',
        },
        component: () => import('./views/Advertisement/AdvertisementForm/AdvertisementForm.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/advertenties/:advertisementId/edit',
        name: 'advertisement-edit',
        meta: {
          title: 'Advertentie aanpassen - IKwatersport',
        },
        component: () => import('./views/Advertisement/AdvertisementForm/AdvertisementForm.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/exports',
        name: 'exports',
        meta: {
          title: 'Exports - IKwatersport',
        },
        component: () => import('./views/Export/ExportSearch/ExportSearch.vue'),
      },
      {
        path: '/evenementen',
        name: 'events',
        meta: {
          title: 'Evenementen - IKwatersport',
        },
        component: () => import('./views/Event/EventSearch/EventSearch.vue'),
      },
      {
        path: '/evenementen-search-frame',
        name: 'eventssearchframe',
        meta: {
          title: 'Evenementen - IKwatersport Frame',
        },
        component: () => import('./views/Event/EventSearchFrame/EventSearchFrame.vue'),
      },
      {
        path: '/evenementen-filter-frame',
        name: 'eventsfilterframe',
        meta: {
          title: 'Evenementen - IKwatersport Frame',
        },
        component: () => import('./views/Event/EventFilterFrame/EventFilterFrame.vue'),
      },
      {
        path: '/evenementen/:eventurl',
        name: 'event-detail',
        meta: {
          title: 'Activiteit detailpagina - IKwatersport',
        },
        component: () => import('./views/Event/EventDetail/EventDetail.vue'),
      },
      {
        path: '/evenementen/:eventurl/register',
        name: 'event-register',
        meta: {
          title: 'Deelnemen aan Activiteit - IKwatersport',
        },
        component: () => import('./views/Event/EventRegister/EventRegister.vue'),
      },
      {
        path: '/clubs/:cluburl/evenement/create',
        name: 'event-create',
        meta: {
          title: 'Aanmaken van activiteit - IKwatersport',
        },
        component: () => import('./views/Event/EventForm/EventForm.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/clubs/:cluburl/evenement/:eventurl/edit',
        name: 'event-edit',
        meta: {
          title: 'Activiteit aanpassen - IKwatersport',
        },
        component: () => import('./views/Event/EventForm/EventForm.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/clubs',
        name: 'clubs',
        meta: {
          title: 'Clubs - IKwatersport',
        },
        component: () => import('./views/Club/ClubSearch/ClubSearch.vue'),
      },
      {
        path: '/clubs/create',
        name: 'club-create',
        meta: {
          title: 'Club aanmaken - IKwatersport',
        },
        component: () => import('./views/Club/ClubForm/ClubForm.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/clubs/:cluburl',
        name: 'club-detail',
        meta: {
          title: 'Club detailpagina - IKwatersport',
        },
        component: () => import('./views/Club/ClubDetail/ClubDetail.vue'),
      },
      {
        path: '/clubs/:cluburl/edit',
        name: 'club-edit',
        meta: {
          title: 'Club aanpassen - IKwatersport',
        },
        component: () => import('./views/Club/ClubForm/ClubForm.vue'),
      },
      {
        path: '/groepen',
        name: 'groups',
        meta: {
          title: 'Groepen - IKwatersport',
        },
        component: () => import('./views/Group/GroupSearch/GroupSearch.vue'),
      },
      {
        path: '/groepen/create',
        name: 'group-create',
        meta: {
          title: 'Groep aanmaken - IKwatersport',
        },
        component: () => import('./views/Group/GroupForm/GroupForm.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/groepen/:groupurl/edit',
        name: 'group-edit',
        meta: {
          title: 'Groep aanpassen - IKwatersport',
        },
        component: () => import('./views/Group/GroupForm/GroupForm.vue'),
      },
      {
        path: '/groepen/:groupurl',
        name: 'group-detail',
        meta: {
          title: 'Groep detailpagina - IKwatersport',
        },
        component: () => import('./views/Group/GroupDetail/GroupDetail.vue'),
      },
      {
        path: '/uitdagingen',
        name: 'challenges',
        meta: {
          title: 'Uitdagingen - IKwatersport',
        },
        component: () => import('./views/Challenge/Challenges/Challenges.vue'),
      },
      {
        path: '/uitdagingen/:challengename/:groupname?',
        name: 'challenge-detail',
        meta: {
          title: 'Uitdagingen detailpagina - IKwatersport',
        },
        component: () => import('./views/Challenge/ChallengeDetail/ChallengeDetail.vue'),
      },
      {
        path: '/activiteiten/invoeren',
        name: 'activity-create',
        meta: {
          title: 'Activiteit aanmaken - IKwatersport',
        },
        component: () => import('./views/Activity/Activity.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/activiteiten/:activityId/edit',
        name: 'activity-edit',
        meta: {
          title: 'Activiteit aanpassen - IKwatersport',
        },
        component: () => import('./views/Activity/Activity.vue'),
        beforeEnter: ifAuthenticated,
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
          title: 'Wachtwoord opnieuw instellen - IKwatersport',
        },
        component: () => import('./views/ResetPassword/ResetPassword.vue'),
      },
      {
        path: '/contact',
        name: 'contact',
        meta: {
          title: 'Contact - IKwatersport',
        },
        component: () => import('./views/Contact/Contact.vue'),
      },
      {
        path: '/privacy-verklaring',
        name: 'privacy',
        meta: {
          title: 'Privacyverklaring - IKwatersport',
        },
        component: () => import('./views/Privacy/Privacy.vue'),
      },
      {
        path: '/algemene-voorwaarden',
        name: 'terms',
        meta: {
          title: 'Algemene voorwaarden - IKwatersport',
        },
        component: () => import('./views/Terms/Terms.vue'),
      },
      {
        path: '*',
        name: '404',
        meta: {
          title: 'Pagina niet gevonden - IKwatersport',
        },
        component: () => import('./views/404/404.vue'),
      },
    ],
  });
}
