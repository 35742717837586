export default {
  name: 'Cta',
  components: {},
  props: {},
  data: () => ({

  }),
  computed: {},
  methods: {},
};
