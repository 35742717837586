import { api } from '@/utils';
import DISCIPLINE_MUTATION_TYPES from './discipline.mutation-types';

const DISCIPLINE_ACTIONS = {
  [DISCIPLINE_MUTATION_TYPES.FETCH_DATA_DISCIPLINE_LIST]({ commit }) {
    return new Promise((resolve, reject) => {
      commit(DISCIPLINE_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get('discipline')
        .then((response) => {
          const disciplineList = response.data;
          commit(DISCIPLINE_MUTATION_TYPES.SET_DISCIPLINE_LIST, disciplineList);
          commit(DISCIPLINE_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(DISCIPLINE_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
};

export default DISCIPLINE_ACTIONS;
