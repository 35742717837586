export default {
  name: 'Pagination',
  components: {},
  props: {
    firstRowOnPage: Number,
    lastRowOnPage: Number,
    totalItems: Number,
  },
  data: () => ({

  }),
  computed: {},
  methods: {
    pageUp() {
      this.$emit('pageUp');
    },
    pageDown() {
      this.$emit('pageDown');
    },
  },
};
