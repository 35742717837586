import ACTIVITY_INITIAL_STATE from './activity.initial-state';
import ACTIVITY_MUTATIONS from './activity.mutations';
import ACTIVITY_GETTERS from './activity.getters';
import ACTIVITY_ACTIONS from './activity.actions';

/* ==========================================================================
   CLUB MODULE STORE
   ========================================================================== */
const activityStore = {
  name: 'activity',
  namespaced: true,
  state: () => ACTIVITY_INITIAL_STATE(),
  mutations: ACTIVITY_MUTATIONS,
  actions: ACTIVITY_ACTIONS,
  getters: ACTIVITY_GETTERS,
};

// eslint-disable-next-line import/prefer-default-export
export { activityStore };
