/* eslint-disable no-unused-vars */

import { api } from '@/utils';
import TAG_MUTATION_TYPES from './tag.mutation-types';

const TAG_ACTIONS = {
  [TAG_MUTATION_TYPES.FETCH_DATA_TAG_LIST]({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('tags')
        .then((response) => {
          commit(TAG_MUTATION_TYPES.SET_TAG_LIST, response.data);
          resolve();
        })
        .catch((error) => {
          commit(TAG_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
};

export default TAG_ACTIONS;
