export default () => ({
  challenges: [],
  challenge: {},
  status: {
    loading: false,
    error: false,
  },
  rankingFilter: {
    query: '',
    pageSize: 8,
  },
  buttonLoading: false,
  userRank: {},
  ranking: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
});
