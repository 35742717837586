import _ from 'lodash';

export default {
  name: 'UserActivities',
  components: {
  },
  props: ['tableData', 'showConfirmationModal', 'confirmationModalIsActive', 'userId'],
  data: () => ({
    tableFields: [
      {
        name: '__slot:discipline',
      },
      {
        name: '__slot:location',
      },
      {
        name: '__slot:tookplaceon',
      },
      {
        name: '__slot:distanceInKm',
      },
      {
        name: '__slot:time',
      },
      {
        name: '__slot:actions',
      },
    ],
    showConfirmationModal2: false,
    confirmationModalIsActive2: false,
  }),
  computed: {

  },
  methods: {
    formatDuration(durationInMs) {
      if (durationInMs !== 0) {
        const h = Math.floor(durationInMs / 1000 / 60 / 60);
        const m = Math.floor((durationInMs / 1000 / 60 / 60 - h) * 60);

        if (h) return `${h}h ${m}min`;
        return `${m}min`;
      }
      return '/';
    },
    formatDistance(distance) {
      if (distance !== 0) return `${distance} km`;
      return '/';
    },
    onDataManager() {
      return this.tableData;
    },
    exportActivities() {
      console.log('ACTIVITIES EXPORTED');
    },
    editActivity(rowData) {
      this.$router.push({ name: 'activity-edit', params: { activityId: rowData.id } });
    },
    onDelete(rowData) {
      this.$emit('showConfirmationModalFunction', rowData);
    },
  },
  watch: {
    tableData() {
      this.$refs.vuetable.refresh();
    },
  },
  created() {
    this.confirmationModalIsActive2 = _.clone(this.confirmationModalIsActive);
    this.showConfirmationModal2 = _.clone(this.showConfirmationModal);
  },
};
