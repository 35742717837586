/* eslint-disable no-unused-vars */
// disabled lint, commit is required in actions

import { api } from '@/utils';
import CLUB_MUTATION_TYPES from './club.mutation-types';

const CLUB_ACTIONS = {
  [CLUB_MUTATION_TYPES.FETCH_DATA_CLUB_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(CLUB_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(CLUB_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('club/get', params)
        .then((response) => {
          commit(CLUB_MUTATION_TYPES.APPEND_CLUB_LIST, response.data);
          commit(CLUB_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(CLUB_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CLUB_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(CLUB_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.FETCH_DATA_USER_CLUB_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(CLUB_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(CLUB_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('user/clubs', params)
        .then((response) => {
          commit(CLUB_MUTATION_TYPES.APPEND_USER_CLUB_LIST, response.data);
          commit(CLUB_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(CLUB_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        }).catch((error) => {
          commit(CLUB_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(CLUB_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.FETCH_DATA_CLUB_MARKERS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(CLUB_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.post('club/get', params)
        .then((response) => {
          commit(CLUB_MUTATION_TYPES.SET_CLUB_MARKERS, response.data);
          commit(CLUB_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CLUB_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.FETCH_DATA_CLUB]({ commit }, url) {
    return new Promise((resolve, reject) => {
      commit(CLUB_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get(`club/${url}`)
        .then((response) => {
          const club = response.data;
          commit(CLUB_MUTATION_TYPES.SET_CLUB, club);
          commit(CLUB_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CLUB_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.FETCH_DATA_EDIT_CLUB]({ commit }, url) {
    return new Promise((resolve, reject) => {
      commit(CLUB_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get(`club/edit/${url}`)
        .then((response) => {
          const club = response.data;
          commit(CLUB_MUTATION_TYPES.SET_CLUB, club);
          commit(CLUB_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CLUB_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.FETCH_DATA_CLUB_EVENTS_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(CLUB_MUTATION_TYPES.SET_CLUB_EVENTS_LOADING_STATE, true);
      let url = `club/${params.clubId}/events?`;
      if (params.page) {
        url += `page=${params.page}&`;
      }
      if (params.pageSize) {
        url += `pageSize=${params.pageSize}`;
      }
      var model = { search: params.search };
      api.get(url, { params: model })
        .then((response) => {
          commit(CLUB_MUTATION_TYPES.APPEND_CLUB_EVENTS_LIST, response.data);
          commit(CLUB_MUTATION_TYPES.SET_CLUB_EVENTS_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CLUB_MUTATION_TYPES.SET_CLUB_EVENTS_LOADING_STATE, false);
          commit(CLUB_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.FETCH_DATA_PAST_CLUB_EVENTS_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(CLUB_MUTATION_TYPES.SET_CLUB_EVENTS_LOADING_STATE, true);
      let url = `club/${params.clubId}/events/past?`;
      if (params.page) {
        url += `page=${params.page}&`;
      }
      if (params.pageSize) {
        url += `pageSize=${params.pageSize}`;
      }

      var model = { search: params.searchPast };
      api.get(url, { params: model })
        .then((response) => {
          commit(CLUB_MUTATION_TYPES.APPEND_PAST_CLUB_EVENTS_LIST, response.data);
          commit(CLUB_MUTATION_TYPES.SET_CLUB_EVENTS_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          commit(CLUB_MUTATION_TYPES.SET_CLUB_EVENTS_LOADING_STATE, false);
          commit(CLUB_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.FETCH_DATA_CLUB_FOLLOWERS_LIST]({ commit, rootState }, params) {
    return new Promise((resolve, reject) => {
      commit(CLUB_MUTATION_TYPES.SET_FOLLOWERS_LOADING_STATE, true);
      let url = '';
      if (params.isAdmin) {
        url = `club/${params.clubId}/admin/followers?`;
      } else {
        url = `club/${params.clubId}/followers?`;
      }
      if (params.page) {
        url += `page=${params.page}&`;
      }
      if (params.pageSize) {
        url += `pageSize=${params.pageSize}`;
      }

      api.get(url)
        .then((response) => {
          const clubFollowersList = response.data.results;
          commit(CLUB_MUTATION_TYPES.APPEND_CLUB_FOLLOWERS_LIST, clubFollowersList);
          commit(CLUB_MUTATION_TYPES.SET_FOLLOWERS_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CLUB_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.FOLLOW_CLUB]({ commit }, clubId) {
    return new Promise((resolve, reject) => {
      api.post(`club/${clubId}/follow`)
        .then((response) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.UNFOLLOW_CLUB]({ commit }, clubId) {
    return new Promise((resolve, reject) => {
      api.post(`club/${clubId}/unfollow`)
        .then((response) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.ADD_CLUB_ADMIN]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.post(`club/${params.clubId}/addadmin`, params)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.REMOVE_CLUB_ADMIN]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.post(`club/${params.clubId}/removeadmin`, params)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.CREATE_CLUB]({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const formData = new FormData();
      formData.append('Name', params.name);
      formData.append('Abbreviation', params.abbreviation);
      formData.append('Phone', params.phone);
      formData.append('Website', params.website);
      formData.append('Email', params.email);
      formData.append('Mollie', params.mollie);
      formData.append('Address_Street', params.address_Street);
      formData.append('Address_Nr', params.address_Nr);
      formData.append('Address_Postcode', params.address_Postcode);
      formData.append('Address_City', params.address_City);
      formData.append('Address_Country', params.address_Country);
      formData.append('Address_Province', params.address_Province);
      formData.append('ContactPerson', params.contactPerson);
      formData.append('ContactEmail', params.contactEmail);
      formData.append('ContactPhone', params.contactPhone);
      formData.append('MembershipInfo', params.membershipInfo);
      formData.append('MembershipLink', params.membershipLink);
      formData.append('MembershipCallToAction', params.membershipCallToAction);
      formData.append('Logo', params.logo);
      formData.append('Lat', params.lat);
      formData.append('Long', params.long);
      formData.append('AboutUs', params.aboutUs);
      formData.append('ClubId', params.clubId);
      formData.append('ClubURL', params.clubUrl);
      formData.append('Image', params.image);
      formData.append('CoverImage', params.coverImage);

      let i = 0;
      params.clubDisciplines.forEach((discipline) => {
        formData.append(`ClubDisciplines[${i}]`, discipline);
        i += 1;
      });

      let t = 0;
      if (params.tags !== undefined) {
        params.tags.forEach((tag) => {
          formData.append(`Tags[${t}]`, tag.text);
          t += 1;
        });
      }

      api.post('club', formData, config)
        .then((response) => {
          commit(CLUB_MUTATION_TYPES.SET_CLUB_ID, response.data);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.EDIT_CLUB]({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const formData = new FormData();
      formData.append('Name', params.name);
      formData.append('Abbreviation', params.abbreviation);
      formData.append('Phone', params.phone);
      formData.append('Website', params.website);
      formData.append('Email', params.email);
      formData.append('Mollie', params.mollie);
      formData.append('Address_Street', params.address_Street);
      formData.append('Address_Nr', params.address_Nr);
      formData.append('Address_Postcode', params.address_Postcode);
      formData.append('Address_City', params.address_City);
      formData.append('Address_Country', params.address_Country);
      formData.append('Address_Province', params.address_Province);
      formData.append('ContactPerson', params.contactPerson);
      formData.append('ContactEmail', params.contactEmail);
      formData.append('ContactPhone', params.contactPhone);
      formData.append('MembershipInfo', params.membershipInfo);
      formData.append('MembershipLink', params.membershipLink);
      formData.append('MembershipCallToAction', params.membershipCallToAction);
      formData.append('Logo', params.logo);
      formData.append('Lat', params.lat);
      formData.append('Long', params.long);
      formData.append('AboutUs', params.aboutUs);
      formData.append('ClubId', params.clubId);
      formData.append('ClubURL', params.clubUrl);
      formData.append('Image', params.image);
      formData.append('CoverImage', params.coverImage);

      let i = 0;
      params.clubDisciplines.forEach((discipline) => {
        formData.append(`ClubDisciplines[${i}]`, discipline);
        i += 1;
      });

      let t = 0;
      if (params.tags !== undefined) {
        params.tags.forEach((tag) => {
          formData.append(`Tags[${t}]`, tag.text);
          t += 1;
        });
      }

      api.put(`club/${params.id}`, formData, config)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [CLUB_MUTATION_TYPES.REMOVE_CLUB_FOLLOWER]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.post('club/removeFollower', params).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [CLUB_MUTATION_TYPES.CREATE_PARTICIPANTS_CSV]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.get(`club/${params.clubId}/csv`).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [CLUB_MUTATION_TYPES.CREATE_FISCAL_CSV]({ commit }, params) {
    console.log(params);
    return new Promise((resolve, reject) => {
      api.get(`club/${params.clubId}/${params.year}/fiscalinfo`).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};

export default CLUB_ACTIONS;
