/* eslint-disable prefer-destructuring */
import isServer from '@/utils/isServer';
import moment from 'moment';

if (!isServer) {
  var VueSlider = require('vue-slider-component');
}

export default {
  name: 'FilterIFrame',
  components: {
    VueSlider,
  },
  props: {},
  data: () => ({
    filter: {
      children: '',
      week: null,
      fromAge: 0,
      toAge: 100,
    },
    childrenCount: [...Array(21).keys()].slice(1),
  }),
  computed: {
    age: {
      get() {
        return [this.filter.fromAge, this.filter.toAge];
      },
      set(ageSliderValue) {
        [this.filter.fromAge, this.filter.toAge] = ageSliderValue;
      },
    },
    isFrame() {
      return this.$route.name.includes('frame');
    },
    weeks() {
      var start = moment().subtract('weeks', 1);
      var end = moment().add('years', 1);
      var day = 1;

      var result = [];
      var current = start.clone();

      while (current.day(7 + day).isBefore(end)) {
        result.push(current.clone().toISOString());
      }

      return result;
    },
  },
  methods: {
    updateChildren(count) {
      this.filter.children = count;
    },
    weekOption(option) {
      return `Week ${moment(option).format('W')} (${moment(option).format('DD/MM')}-${moment(option).add('days', 6).format('DD/MM')})`;
    },
    openEvent() {
      if (this.isFrame) {
        let fullpath = '/evenementen?';
        if (this.filter.fromAge !== 0 || this.filter.toAge !== 100) fullpath = fullpath.concat(`leeftijd=${this.filter.fromAge}-${this.filter.toAge}`);
        if (this.filter.children) fullpath = fullpath.concat(`&beschikbareplaatsen=${this.filter.children}-100`);
        if (this.filter.week) fullpath = fullpath.concat(`&datum=${moment(this.filter.week).format('YYYY-MM-DD')}_${moment(this.filter.week).add(6, 'd').format('YYYY-MM-DD')}`);
        fullpath = fullpath.concat('&tags=watersportkamp');
        const routeData = this.$router.resolve({ path: fullpath });
        window.open(routeData.href, '_blank');
      }
    },
    save() {
      this.openEvent();
    },
  },
};
