/* eslint-disable no-unused-vars */

import { api } from '@/utils';
import ADVERTISEMENT_MUTATION_TYPES from './advertisement.mutation-types';

const ADVERTISEMENT_ACTIONS = {
  [ADVERTISEMENT_MUTATION_TYPES.FETCH_DATA_ADVERTISEMENT_BESTMATCH]({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(ADVERTISEMENT_MUTATION_TYPES.SET_LOADING_STATE, true);
      let tempUrl = 'advertisement/bestmatch';
      let url = '';

      let i = 0;
      if (params.tags) {
        tempUrl += '?';
        params.tags.forEach((tag) => {
          tempUrl += `Tags=${tag.name}&`;
          i += 1;
        });
        url = tempUrl.substring(0, tempUrl.length - 1);
      }

      api.get(url)
        .then((response) => {
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_ADVERTISEMENT_BESTMATCH, response.data);
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [ADVERTISEMENT_MUTATION_TYPES.FETCH_DATA_ADVERTISEMENT_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(ADVERTISEMENT_MUTATION_TYPES.SET_LOADING_STATE, true);
      let url = 'advertisement';
      if (params.page) {
        url += `?Page=${params.page}&PageSize=${params.pageSize}`;
      }
      api.get(url)
        .then((response) => {
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_ADVERTISEMENT_LIST, response.data);
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        }).catch((error) => {
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [ADVERTISEMENT_MUTATION_TYPES.FETCH_DATA_ADVERTISEMENT]({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit(ADVERTISEMENT_MUTATION_TYPES.SET_LOADING_STATE, true);
      commit(ADVERTISEMENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      api.get(`advertisement/${id}`)
        .then((response) => {
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_ADVERTISEMENT, response.data);
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        }).catch((error) => {
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_ERROR, error);
          commit(ADVERTISEMENT_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          reject(error);
        });
    });
  },
  [ADVERTISEMENT_MUTATION_TYPES.CREATE_NEW_ADVERTISEMENT]({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const formData = new FormData();
      formData.append('Name', params.name);
      formData.append('From', params.from);
      formData.append('Until', params.until);
      formData.append('Link', params.link);
      formData.append('Picture', params.picture);

      let i = 0;
      if (params.tags !== undefined) {
        params.tags.forEach((tag) => {
          formData.append(`Tags[${i}]`, tag.text);
          i += 1;
        });
      }
      api.post('advertisement', formData, config)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [ADVERTISEMENT_MUTATION_TYPES.UPDATE_ADVERTISEMENT]({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const formData = new FormData();
      formData.append('Name', params.name);
      formData.append('From', params.from);
      formData.append('Until', params.until);
      formData.append('Link', params.link);
      formData.append('Picture', params.picture);

      let i = 0;
      if (params.tags !== undefined) {
        params.tags.forEach((tag) => {
          formData.append(`Tags[${i}]`, tag.text);
          i += 1;
        });
      }

      api.put(`advertisement/${params.id}`, formData, config)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [ADVERTISEMENT_MUTATION_TYPES.DELETE_ADVERTISEMENT]({ commit }, id) {
    const advertisement = { advertisementId: id };
    return new Promise((resolve, reject) => {
      api.delete(`advertisement/${id}`, advertisement)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
};

export default ADVERTISEMENT_ACTIONS;
