import GROUP_MUTATION_TYPES from './group.mutation-types';
import initialState from './group.initial-state';

const GROUP_MUTATIONS = {
  [GROUP_MUTATION_TYPES.APPEND_GROUP_LIST](state, groups) {
    state.groups.currentPage = groups.currentPage;
    state.groups.pageCount = groups.pageCount;
    state.groups.rowCount = groups.rowCount;
    state.groups.firstRowOnPage = groups.firstRowOnPage;
    state.groups.lastRowOnPage = groups.lastRowOnPage;
    state.groups.results.push(...groups.results);
  },
  [GROUP_MUTATION_TYPES.APPEND_USER_GROUP_LIST](state, groups) {
    state.userGroups.currentPage = groups.currentPage;
    state.userGroups.pageCount = groups.pageCount;
    state.userGroups.rowCount = groups.rowCount;
    state.userGroups.firstRowOnPage = groups.firstRowOnPage;
    state.userGroups.lastRowOnPage = groups.lastRowOnPage;
    state.userGroups.results.push(...groups.results);
  },
  [GROUP_MUTATION_TYPES.CLEAR_GROUP_LIST](state) {
    state.groups = initialState().groups;
  },
  [GROUP_MUTATION_TYPES.CLEAR_USER_GROUP_LIST](state) {
    state.userGroups = initialState().userGroups;
  },
  [GROUP_MUTATION_TYPES.CLEAR_GROUP_FOLLOWERS_LIST](state) {
    state.groupFollowers = [];
  },
  [GROUP_MUTATION_TYPES.APPEND_GROUP_FOLLOWERS_LIST](state, followers) {
    state.groupFollowers.push(...followers);
  },
  [GROUP_MUTATION_TYPES.SET_GROUP](state, GROUP) {
    state.group = GROUP;
  },
  [GROUP_MUTATION_TYPES.SET_GROUP_MARKERS](state, markers) {
    state.markers = markers;
  },
  [GROUP_MUTATION_TYPES.SET_GROUP_ID](state, groupId) {
    state.groupId = groupId;
  },
  [GROUP_MUTATION_TYPES.SET_ERROR](state, error) {
    state.status.loading = false;
    state.status.error = error;
  },
  [GROUP_MUTATION_TYPES.SET_FOLLOWERS_LOADING_STATE](state, isLoading) {
    state.status.followersLoading = isLoading;
  },
  [GROUP_MUTATION_TYPES.SET_LOADING_STATE](state, isLoading) {
    state.status.loading = isLoading;
  },
  [GROUP_MUTATION_TYPES.SET_BUTTON_LOADING_STATE](state, isLoading) {
    state.buttonLoading = isLoading;
  },
  [GROUP_MUTATION_TYPES.SET_GROUP_FILTER_DISCIPLINE](state, filterDiscipline) {
    state.groupFilter.discipline = filterDiscipline;
  },
  [GROUP_MUTATION_TYPES.SET_GROUP_FILTER_LOCATION](state, filterLocation) {
    state.groupFilter.location = filterLocation;
  },
  [GROUP_MUTATION_TYPES.SET_GROUP_FILTER_QUERY](state, filterQuery) {
    state.groupFilter.query = filterQuery;
  },
  [GROUP_MUTATION_TYPES.CLEAR_GROUP_FILTER_QUERY](state) {
    state.groupFilter = initialState().groupFilter;
  },
};

export default GROUP_MUTATIONS;
