const EVENT_MUTATION_TYPES = Object.freeze({
  FETCH_DATA_EVENT_LIST: 'FETCH_DATA_EVENT_LIST',
  FETCH_DATA_USER_EVENT_LIST: 'FETCH_DATA_USER_EVENT_LIST',
  FETCH_DATA_EVENT: 'FETCH_DATA_EVENT',
  FETCH_DATA_EDIT_EVENT: 'FETCH_DATA_EDIT_EVENT',
  FETCH_DATA_EVENT_PARTICIPANTS_LIST: 'FETCH_DATA_EVENT_PARTICIPANTS_LIST',
  FETCH_FILTERED_DATA_EVENT_LIST: 'FETCH_FILTERED_DATA_EVENT_LIST',
  FETCH_DATA_EVENT_TYPES: 'FETCH_DATA_EVENT_TYPES',
  FETCH_DATA_EVENT_MARKERS: 'FETCH_DATA_EVENT_MARKERS',
  FETCH_FILTERED_DATA_EVENT_MARKERS: 'FETCH_FILTERED_DATA_EVENT_MARKERS',
  FETCH_DATA_EVENT_ORDER_PRICE: 'FETCH_DATA_EVENT_ORDER_PRICE',
  FETCH_DATA_EVENT_ORDER_STATUS: 'FETCH_DATA_EVENT_ORDER_STATUS',
  CREATE_PARTICIPANTS_CSV: 'CREATE_PARTICIPANTS_CSV',
  CREATE_CANCELLED_PARTICIPANTS_CSV: 'CREATE_CANCELLED_PARTICIPANTS_CSV',
  FETCH_DATA_FEATURED_EVENTS: 'FETCH_DATA_FEATURED_EVENTS',

  CREATE_NEW_EVENT: 'CREATE_NEW_EVENT',
  UPDATE_EVENT: 'UPDATE_EVENT',
  UPDATE_EVENT_PARTICIPANT: 'UPDATE_EVENT_PARTICIPANT',
  REGISTER_PARTICIPANTS: 'REGISTER_PARTICIPANTS',
  DELETE_EVENT: 'DELETE_EVENT',

  SET_EVENT: 'SET_EVENT',
  SET_EVENT_TYPES: 'SET_EVENT_TYPES',
  SET_EVENT_MARKERS: 'SET_EVENT_MARKERS',
  SET_EVENT_ORDER_PRICE: 'SET_EVENT_ORDER_PRICE',
  SET_EVENT_ORDER_STATUS: 'SET_EVENT_ORDER_STATUS',
  CLEAR_EVENT_FILTER_QUERY: 'CLEAR_EVENT_FILTER_QUERY',
  SET_FEATURED_EVENTS: 'SET_FEATURED_EVENTS',

  APPEND_EVENT_LIST: 'APPEND_EVENT_LIST',
  APPEND_USER_EVENT_LIST: 'APPEND_USER_EVENT_LIST',
  APPEND_EVENT_PARTICIPANTS_LIST: 'APPEND_EVENT_PARTICIPANTS_LIST',

  CLEAR_EVENT_LIST: 'CLEAR_EVENT_LIST',
  CLEAR_USER_EVENT_LIST: 'CLEAR_USER_EVENT_LIST',
  CLEAR_EVENT_PARTICIPANTS_LIST: 'CLEAR_EVENT_PARTICIPANTS_LIST',

  SET_EVENT_FILTER: 'SET_EVENT_FILTER',
  SET_EVENT_FILTER_AGE: 'SET_EVENT_FILTER_AGE',
  SET_EVENT_FILTER_SHOW_PRIVATE: 'SET_EVENT_FILTER_SHOW_PRIVATE',
  SET_EVENT_FILTER_AVAILABLE_PLACES: 'SET_EVENT_FILTER_AVAILABLE_PLACES',
  SET_EVENT_FILTER_PRICE: 'SET_EVENT_FILTER_PRICE',
  SET_EVENT_FILTER_DATE: 'SET_EVENT_FILTER_DATE',
  SET_EVENT_FILTER_SHOW_STARTED: 'SET_EVENT_FILTER_SHOW_STARTED',
  SET_EVENT_FILTER_TAGS: 'SET_EVENT_FILTER_TAGS',
  SET_EVENT_FILTER_TYPE: 'SET_EVENT_FILTER_TYPE',
  SET_EVENT_FILTER_QUERY: 'SET_EVENT_FILTER_QUERY',
  SET_EVENT_FILTER_DISCIPLINE: 'SET_EVENT_FILTER_DISCIPLINE',
  SET_EVENT_FILTER_LOCATION: 'SET_EVENT_FILTER_LOCATION',
  GET_EVENT_FILTER: 'GET_EVENT_FILTER',

  REMOVE_PARTICIPANT: 'REMOVE_PARTICIPANT',

  SET_ERROR: 'SET_ERROR',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_BUTTON_LOADING_STATE: 'SET_BUTTON_LOADING_STATE',
  SET_PARTICIPANTS_LOADING_STATE: 'SET_PARTICIPANTS_LOADING_STATE',
  SET_REGISTER_PARTICIPANTS_LOADING_STATE: 'SET_REGISTER_PARTICIPANTS_LOADING_STATE',
});

export default EVENT_MUTATION_TYPES;
