import TAG_MUTATIONS_TYPES from './tag.mutation-types';

const TAG_MUTATIONS = {
  [TAG_MUTATIONS_TYPES.SET_TAG_LIST](state, tags) {
    state.tags = tags;
  },
  [TAG_MUTATIONS_TYPES.SET_ERROR](state, error) {
    state.error = error;
  },
};

export default TAG_MUTATIONS;
