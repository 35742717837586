import { ShareButton } from '@/components';

export default {
  name: 'ShareModal',
  components: {
    [ShareButton.name]: ShareButton,
  },
  props: {
    shareTitle: '',
    showShareModal: false,
  },
  data: () => ({
    shareLinks: [
      { iconLogo: 'facebook-f', externalLinkClass: 'facebook' },
      { iconLogo: 'twitter', externalLinkClass: 'twitter' },
    ],
  }),
  computed: {
    shareLinkUrl() {
      return `${window.location.protocol}//${window.location.hostname}${this.$route.path}`;
    },
  },
  methods: {
    clickOutside() {
      this.$emit('closeModal');
    },
  },
};
