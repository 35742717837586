import { render, staticRenderFns } from "./FilterPrice.vue?vue&type=template&id=6b3c9156&"
import script from "./FilterPrice.controller.js?vue&type=script&lang=js&"
export * from "./FilterPrice.controller.js?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./FilterPrice.styles.scss?vue&type=style&index=0&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "faf65644"
  
)

export default component.exports