/* eslint-disable no-undef */

/* eslint-disable no-unused-vars */
import {
  mapActions, mapGetters,
} from 'vuex';

import isServer from '@/utils/isServer';

if (!isServer) {
  var { VuetablePagination } = require('vuetable-2');
}

export default {
  name: 'ClubFollowersAdmin',
  components: {
    VuetablePagination,
  },
  props: ['tableData', 'clubName'],
  data: () => ({
    tableFields: [
      {
        name: '__slot:participant',
        title: 'Deelnemer',
        // visible: false,
      },
      {
        name: '__slot:joinDate',
        title: 'Volgdatum',
      },
      {
        name: '__slot:birthDate',
        title: 'Geboortedatum',
      },
      {
        name: 'email',
        title: 'Email',
      },
      {
        name: '__slot:isAdmin',
        title: 'Admin',
      },
      {
        name: '__slot:actions',
        title: 'Actie',
        // visible: false,
      },
    ],
  }),
  computed: {
    ...mapGetters('club', {
      getClubFollowersData: 'getFSMAData',
      club: 'getClub',
    }),
  },
  methods: {
    ...mapActions('club', {
      initialiseFSMAData: 'FETCH_DATA',
      addAdmin: 'ADD_CLUB_ADMIN',
      removeAdmin: 'REMOVE_CLUB_ADMIN',
      removeClubFollower: 'REMOVE_CLUB_FOLLOWER',
    }),
    onDataManager() {
      return this.tableData;
    },
    disabledToggle(data) {
      if ((this.tableData.filter((t) => t.isAdmin).length < 2) && data.isAdmin) {
        return true;
      }
      return false;
    },
    adminStateChanged(event, rowData) {
      const paramsObj = {
        clubId: this.club.id,
        userId: rowData.id,
      };

      if (event.target.checked) {
        this.addAdmin(paramsObj).then(() => {
          this.$emit('refreshFollowers');
          this.$toasted.show(`${rowData.firstname} ${rowData.lastname} is nu admin van ${this.clubName}.`, { type: 'success' });
        }).catch((error) => {
          this.$toasted.show(...error.response.data);
        });
      } else {
        this.removeAdmin(paramsObj).then(() => {
          this.$emit('refreshFollowers');
          this.$toasted.show(`${rowData.firstname} ${rowData.lastname} is niet langer admin van ${this.clubName}.`, { type: 'success' });
        }).catch((error) => {
          this.$toasted.show(...error.response.data);
        });
      }
    },
    removeFollowerEvent(event, rowData) {
      const paramsObj = {
        clubId: this.club.id,
        followerId: rowData.id,
      };

      this.removeClubFollower(paramsObj).then(() => {
        this.$emit('refreshFollowers');
        this.$toasted.show(`${rowData.firstname} ${rowData.lastname} is verwijderd als volger.`, { type: 'success' });
      }).catch((error) => {
        this.$toasted.show(...error.response.data);
      });
    },
  },
  watch: {
    tableData() {
      this.$refs.vuetable.refresh();
    },
  },
};
