import CLUB_MUTATION_TYPES from './club.mutation-types';
import initialState from './club.initial-state';

const CLUB_MUTATIONS = {
  [CLUB_MUTATION_TYPES.APPEND_CLUB_LIST](state, clubs) {
    state.clubs.currentPage = clubs.currentPage;
    state.clubs.pageCount = clubs.pageCount;
    state.clubs.rowCount = clubs.rowCount;
    state.clubs.firstRowOnPage = clubs.firstRowOnPage;
    state.clubs.lastRowOnPage = clubs.lastRowOnPage;
    state.clubs.results.push(...clubs.results);
  },
  [CLUB_MUTATION_TYPES.APPEND_USER_CLUB_LIST](state, clubs) {
    state.userClubs.currentPage = clubs.currentPage;
    state.userClubs.pageCount = clubs.pageCount;
    state.userClubs.rowCount = clubs.rowCount;
    state.userClubs.firstRowOnPage = clubs.firstRowOnPage;
    state.userClubs.lastRowOnPage = clubs.lastRowOnPage;
    state.userClubs.results.push(...clubs.results);
  },
  [CLUB_MUTATION_TYPES.CLEAR_CLUB_LIST](state) {
    state.clubs = initialState().clubs;
  },
  [CLUB_MUTATION_TYPES.CLEAR_USER_CLUB_LIST](state) {
    state.userClubs = initialState().userClubs;
  },
  [CLUB_MUTATION_TYPES.SET_CLUB](state, club) {
    state.club = club;
  },
  [CLUB_MUTATION_TYPES.SET_CLUB_ID](state, clubId) {
    state.clubId = clubId;
  },
  [CLUB_MUTATION_TYPES.APPEND_CLUB_EVENTS_LIST](state, events) {
    state.clubEvents.currentPage = events.currentPage;
    state.clubEvents.pageCount = events.pageCount;
    state.clubEvents.rowCount = events.rowCount;
    state.clubEvents.firstRowOnPage = events.firstRowOnPage;
    state.clubEvents.lastRowOnPage = events.lastRowOnPage;
    state.clubEvents.results.push(...events.results);
  },
  [CLUB_MUTATION_TYPES.APPEND_PAST_CLUB_EVENTS_LIST](state, events) {
    state.pastClubEvents.currentPage = events.currentPage;
    state.pastClubEvents.pageCount = events.pageCount;
    state.pastClubEvents.rowCount = events.rowCount;
    state.pastClubEvents.firstRowOnPage = events.firstRowOnPage;
    state.pastClubEvents.lastRowOnPage = events.lastRowOnPage;
    state.pastClubEvents.results.push(...events.results);
  },
  [CLUB_MUTATION_TYPES.CLEAR_CLUB_EVENTS_LIST](state) {
    state.clubEvents = initialState().clubEvents;
  },
  [CLUB_MUTATION_TYPES.CLEAR_PAST_CLUB_EVENTS_LIST](state) {
    state.pastClubEvents = initialState().pastClubEvents;
  },
  [CLUB_MUTATION_TYPES.APPEND_CLUB_FOLLOWERS_LIST](state, followers) {
    state.clubFollowers.push(...followers);
  },
  [CLUB_MUTATION_TYPES.CLEAR_CLUB_FOLLOWERS_LIST](state) {
    state.clubFollowers = [];
  },
  [CLUB_MUTATION_TYPES.SET_ERROR](state, error) {
    state.status.loading = false;
    state.status.error = error;
  },
  [CLUB_MUTATION_TYPES.SET_LOADING_STATE](state, isLoading) {
    state.status.loading = isLoading;
  },
  [CLUB_MUTATION_TYPES.SET_FOLLOWERS_LOADING_STATE](state, isLoading) {
    state.status.followersLoading = isLoading;
  },
  [CLUB_MUTATION_TYPES.SET_CLUB_EVENTS_LOADING_STATE](state, isLoading) {
    state.status.eventsLoading = isLoading;
  },
  [CLUB_MUTATION_TYPES.SET_BUTTON_LOADING_STATE](state, isLoading) {
    state.buttonLoading = isLoading;
  },
  [CLUB_MUTATION_TYPES.SET_CLUB_FILTER_DISCIPLINE](state, filterDiscipline) {
    state.clubFilter.discipline = filterDiscipline;
  },
  [CLUB_MUTATION_TYPES.SET_CLUB_FILTER_LOCATION](state, filterLocation) {
    state.clubFilter.location = filterLocation;
  },
  [CLUB_MUTATION_TYPES.SET_CLUB_FILTER_QUERY](state, filterQuery) {
    state.clubFilter.query = filterQuery;
  },
  [CLUB_MUTATION_TYPES.CLEAR_CLUB_FILTER_QUERY](state) {
    state.clubFilter = initialState().clubFilter;
  },
  [CLUB_MUTATION_TYPES.SET_CLUB_MARKERS](state, markers) {
    state.markers = markers;
  },
};

export default CLUB_MUTATIONS;
