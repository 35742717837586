export default {
  name: 'RedirectModal',
  components: {
  },
  props: {
    redirectTitle: '',
    linkText: '',
    link: '',
    showRedirectModal: false,
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
