/* eslint-disable no-unused-vars */
import { api, createApi } from '@/utils';
import moment from 'moment';
import ACCOUNT_MUTATION_TYPES from './account.mutation-types';

const ACCOUNT_ACTIONS = {
  [ACCOUNT_MUTATION_TYPES.LOGIN]({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, '');
      commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.post('account/token', user)
        .then((response) => {
          const token = response.data.access_token;
          commit(ACCOUNT_MUTATION_TYPES.SET_TOKEN, token);
          createApi();
          api.get('account/profile').then((profileResponse) => {
            commit(ACCOUNT_MUTATION_TYPES.SET_CURRENT_USER, profileResponse.data);
          });
          commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          const errorMessage = [...error.response.data];
          commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, errorMessage);
          reject(error.response.data);
        });
    });
  },
  [ACCOUNT_MUTATION_TYPES.LOGOUT]({ commit }) {
    return new Promise((resolve) => {
      commit(ACCOUNT_MUTATION_TYPES.REMOVE_TOKEN);
      resolve();
    });
  },
  [ACCOUNT_MUTATION_TYPES.REGISTER]({ commit }, registerObj) {
    return new Promise((resolve, reject) => {
      commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.post('account/register', registerObj)
        .then(() => {
          commit(ACCOUNT_MUTATION_TYPES.SET_SUCCESSFUL_REGISTER);
          resolve();
        })
        .catch((error) => {
          commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, error);
          reject(error.response.data);
        });
    });
  },
  [ACCOUNT_MUTATION_TYPES.RESET_PASSWORD]({ commit }, userObj) {
    return new Promise((resolve, reject) => {
      commit(ACCOUNT_MUTATION_TYPES.SET_RESET_PASSORD_LOADING_STATE, true);
      api.post(`account/reset-password?token=${encodeURI(userObj.token)}`, userObj).then(() => {
        commit(ACCOUNT_MUTATION_TYPES.SET_RESET_PASSORD_LOADING_STATE, false);
        resolve();
      }).catch((error) => {
        commit(ACCOUNT_MUTATION_TYPES.SET_RESET_PASSORD_LOADING_STATE, false);
        reject(error);
      });
    });
  },
  [ACCOUNT_MUTATION_TYPES.FORGOT_PASSWORD]({ commit }, emailObj) {
    return new Promise((resolve, reject) => {
      commit(ACCOUNT_MUTATION_TYPES.SET_FORGOT_PASSORD_LOADING_STATE, true);
      api.post('account/forgot-password', emailObj).then(() => {
        commit(ACCOUNT_MUTATION_TYPES.SET_FORGOT_PASSORD_LOADING_STATE, false);
        resolve();
      }).catch((error) => {
        commit(ACCOUNT_MUTATION_TYPES.SET_FORGOT_PASSORD_LOADING_STATE, false);
        reject(error);
      });
    });
  },
  [ACCOUNT_MUTATION_TYPES.FETCH_DATA_EDIT_USER]({ commit }) {
    return new Promise((resolve, reject) => {
      commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get('account/profile').then((profileResponse) => {
        commit(ACCOUNT_MUTATION_TYPES.SET_CURRENT_USER, profileResponse.data);
        commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, false);
        resolve();
      })
        .catch((error) => {
          commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [ACCOUNT_MUTATION_TYPES.EDIT_USER]({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const formData = new FormData();
      formData.append('Email', params.email);
      formData.append('Firstname', params.firstname);
      formData.append('Lastname', params.lastname);
      if (params.addressStreet)formData.append('Address_Street', params.addressStreet);
      if (params.addressNumber)formData.append('Address_Nr', params.addressNumber);
      if (params.addressPostalcode)formData.append('Address_Postcode', params.addressPostalcode);
      if (params.addressCity)formData.append('Address_City', params.addressCity);
      if (params.birthDate)formData.append('BirthDate', moment(params.birthDate, 'DD-MM-YYYY').toISOString(true));
      if (params.sex)formData.append('Sex', params.sex.value);
      if (params.licenseNumber)formData.append('LicenseNumber', params.licenseNumber);
      if (params.phone)formData.append('Phone', params.phone);

      let i = 0;
      params.disciplines.forEach((discipline) => {
        formData.append(`Disciplines[${i}]`, discipline);
        i += 1;
      });
      formData.append('Disciplines[0]', params.disciplines[0]);
      formData.append('Image', params.profilePicture);
      commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, '');
      commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.put('account/profile', formData, config)
        .then((response) => {
          api.get('account/profile').then((profileResponse) => {
            commit(ACCOUNT_MUTATION_TYPES.SET_CURRENT_USER, profileResponse.data);
            commit(ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE, false);
            resolve(response);
          });
        }).catch((error) => {
          commit(ACCOUNT_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
};

export default ACCOUNT_ACTIONS;
