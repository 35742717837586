import { api } from '@/utils';
import CHALLENGE_MUTATION_TYPES from './challenge.mutation-types';

const CHALLENGE_ACTIONS = {
  [CHALLENGE_MUTATION_TYPES.FETCH_DATA_CHALLENGE_LIST]({ commit }) {
    return new Promise((resolve, reject) => {
      commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get('challenge')
        .then((response) => {
          const challengeList = response.data;
          commit(CHALLENGE_MUTATION_TYPES.SET_CHALLENGE_LIST, challengeList);
          commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          commit(CHALLENGE_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },

  [CHALLENGE_MUTATION_TYPES.FETCH_DATA_CHALLENGE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get(`challenge/${id}`)
        .then((response) => {
          const challenge = response.data;
          commit(CHALLENGE_MUTATION_TYPES.SET_CHALLENGE, challenge);
          commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CHALLENGE_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CHALLENGE_MUTATION_TYPES.FETCH_DATA_YEAR_RANKING_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('challenge/year-ranking', params)
        .then((response) => {
          commit(CHALLENGE_MUTATION_TYPES.APPEND_RANKING_LIST, response.data);
          commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(CHALLENGE_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CHALLENGE_MUTATION_TYPES.FETCH_DATA_MARATHON_RANKING_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('challenge/marathon-ranking', params)
        .then((response) => {
          commit(CHALLENGE_MUTATION_TYPES.APPEND_RANKING_LIST, response.data);
          commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(CHALLENGE_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CHALLENGE_MUTATION_TYPES.FETCH_DATA_WATERMAN_RANKING_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('challenge/waterman-ranking', params)
        .then((response) => {
          commit(CHALLENGE_MUTATION_TYPES.APPEND_RANKING_LIST, response.data);
          commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(CHALLENGE_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [CHALLENGE_MUTATION_TYPES.FETCH_DATA_EXPLORER_RANKING_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('challenge/explorer-ranking', params)
        .then((response) => {
          commit(CHALLENGE_MUTATION_TYPES.APPEND_RANKING_LIST, response.data);
          commit(CHALLENGE_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(CHALLENGE_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(CHALLENGE_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
};

export default CHALLENGE_ACTIONS;
