const DISCIPLINE_MUTATION_TYPES = Object.freeze({
  FETCH_DATA_DISCIPLINE_LIST: 'FETCH_DATA_DISCIPLINE_LIST',

  SET_DISCIPLINE_LIST: 'SET_DISCIPLINE_LIST',

  SET_ERROR: 'SET_ERROR',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
});

export default DISCIPLINE_MUTATION_TYPES;
