export default () => ({
  club: {},
  clubs: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
  userClubs: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
  buttonLoading: false,
  markers: [],
  clubFilter: {
    query: '',
    pageSize: 8,
    discipline: null,
    location: null,
  },
  userClubFilter: {
    page: 1,
    pageSize: 6,
  },
  page: 1,
  clubFollowers: [],
  clubEvents: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
  pastClubEvents: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
  status: {
    loading: false,
    followersLoading: false,
    eventsLoading: false,
    error: '',
  },
  clubId: null,
});
