import axios from 'axios';
import _ from 'lodash';
import storageMock from './localStorageMock';

const baseURL = () => {
  let baseUrl = '';
  if (process.env.VUE_APP_WWSV_API === 'api') {
    baseUrl = `${window.location.protocol}//${window.location.hostname}/api`;
  } else {
    baseUrl = process.env.VUE_APP_WWSV_API;
  }

  return baseUrl;
};

// eslint-disable-next-line import/no-mutable-exports
let api = axios.create({
  baseURL: baseURL(),
  timeout: 360000,
  headers: {
    Authorization: `Bearer ${_.get(JSON.parse(storageMock.getItem('token')), 'token', '')}`,
  },
});

const createApi = () => {
  api = axios.create({
    baseURL: baseURL(),
    timeout: 360000,
    headers: {
      Authorization: `Bearer ${_.get(JSON.parse(storageMock.getItem('token')), 'token', '')}`,
    },
  });
};

export {
  api,
  createApi,
};
