import GROUP_INITIAL_STATE from './group.initial-state';
import GROUP_MUTATIONS from './group.mutations';
import GROUP_GETTERS from './group.getters';
import GROUP_ACTIONS from './group.actions';

/* ==========================================================================
   group MODULE STORE
   ========================================================================== */
const groupStore = {
  name: 'group',
  namespaced: true,
  state: () => GROUP_INITIAL_STATE(),
  mutations: GROUP_MUTATIONS,
  actions: GROUP_ACTIONS,
  getters: GROUP_GETTERS,
};

// eslint-disable-next-line import/prefer-default-export
export { groupStore };
