import CHALLENGE_INITIAL_STATE from './challenge.initial-state';
import CHALLENGE_MUTATIONS from './challenge.mutations';
import CHALLENGE_GETTERS from './challenge.getters';
import CHALLENGE_ACTIONS from './challenge.actions';

/* ==========================================================================
   CHALLENGE MODULE STORE
   ========================================================================== */
const challengeStore = {
  name: 'challenge',
  namespaced: true,
  state: () => CHALLENGE_INITIAL_STATE(),
  mutations: CHALLENGE_MUTATIONS,
  actions: CHALLENGE_ACTIONS,
  getters: CHALLENGE_GETTERS,
};

// eslint-disable-next-line import/prefer-default-export
export { challengeStore };
