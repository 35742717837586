import {
  Footer,
  HeaderNavigation,
} from '@/components';
import {
  mapActions,
} from 'vuex';
import _ from 'lodash';
import LocalStorage from '@/utils/localStorageMock';
import isServer from './utils/isServer';

export default {
  name: 'App',
  components: {
    [Footer.name]: Footer,
    [HeaderNavigation.name]: HeaderNavigation,
  },
  props: {},
  data: () => ({}),
  computed: {
    isFrame() {
      return this.$route.name.includes('frame');
    },
  },
  methods: {
    ...mapActions('account', {
      initialiseUser: 'FETCH_DATA_EDIT_USER',
      logOut: 'LOGOUT',
    }),
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || 'IKwatersport';
    },
  },
  created() {
    if (this.$isLoggedIn) {
      this.initialiseUser();
    }

    const expiry = _.get(JSON.parse(LocalStorage.getItem('token')), 'expiry', '');
    if (expiry !== '' && (expiry - Date.now()) < 0) {
      this.logOut();
    }
    if (this.isFrame && !isServer) {
      document.body.classList.add('c-body__frame');
    }
    if (!isServer) {
      this.$cookiebot.consentBanner();
    }
  },
};
