import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=32c2ca1a&scoped=true&"
import script from "./Loader.controller.js?vue&type=script&lang=js&"
export * from "./Loader.controller.js?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Loader.styles.scss?vue&type=style&index=0&id=32c2ca1a&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "32c2ca1a",
  "ae67b22e"
  
)

export default component.exports