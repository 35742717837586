export default () => ({
  userActivities: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
  userActivity: {
    location: '',
    distanceInKm: 0,
    durationInMs: 0,
    tookPlaceOn: null,
    discipline: '',
  },
  buttonLoading: false,
  userActivityFilter: {
    page: 1,
    pageSize: 5,
  },
  status: {
    loading: false,
    followersLoading: false,
    groupsLoading: false,
    error: '',
  },
  page: 1,
  activityId: null,
});
