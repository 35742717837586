export default {
  name: 'FilterHead',
  components: {},
  props: {
    filterHead: {
      icon: '',
      text: '',
    },
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {},
  methods: {
    changeOpenState() {
      this.isOpen = !this.isOpen;
      this.$emit('openFilter', true);
    },
  },
  created() {
  },
};
