import {
  mapGetters,
} from 'vuex';

export default {
  name: 'Challenge',
  components: {},
  props: {
    challenge: {},
  },
  data: () => ({
    imageName: '',
  }),
  computed: {
    ...mapGetters('group', {
      group: 'getGroup',
    }),
  },
  methods: {},
  created() {
    switch (this.challenge.name) {
      case '365 Challenge':
        this.imageName = '365';
        break;
      case 'Marathon Challenge':
        this.imageName = 'marathon';
        break;
      case 'Explorer Challenge':
        this.imageName = 'explorer';
        break;
      case 'Waterman Challenge':
        this.imageName = 'waterman';
        break;
      default:
        break;
    }
  },
};
