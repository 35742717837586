import ADVERTISEMENT_INITIAL_STATE from './advertisement.initial-state';
import ADVERTISEMENT_MUTATIONS from './advertisement.mutations';
import ADVERTISEMENT_GETTERS from './advertisement.getters';
import ADVERTISEMENT_ACTIONS from './advertisement.actions';

/* ==========================================================================
   ADVERTISEMENTs MODULE STORE
   ========================================================================== */
const advertisementStore = {
  name: 'advertisement',
  namespaced: true,
  state: () => ADVERTISEMENT_INITIAL_STATE(),
  mutations: ADVERTISEMENT_MUTATIONS,
  actions: ADVERTISEMENT_ACTIONS,
  getters: ADVERTISEMENT_GETTERS,
};

// eslint-disable-next-line import/prefer-default-export
export { advertisementStore };
