/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import Vuex from 'vuex';
import { STORE_MODULES } from './store-modules';

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',

    state: () => ({}),
    mutations: {},
    actions: {},
    modules: STORE_MODULES,
  });
}
