import {
  mapGetters,
} from 'vuex';

export default {
  name: 'NavigationProfile',
  components: {},
  props: {},
  data: () => ({
    error: false,
  }),
  computed: {
    ...mapGetters('account', {
      currentUser: 'getCurrentUser',
    }),
  },
  methods: {},
};
