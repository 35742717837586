const CHALLENGE_GETTERS = {
  getChallengeList: (state) => state.challenges,
  getChallenge: (state) => state.challenge,
  getRanking: (state) => state.ranking,
  getUserRank: (state) => state.userRank,
  getRankingFilter: (state) => state.rankingFilter,
  getLoadingState: (state) => state.status.loading,
  getButtonLoadingState: (state) => state.buttonLoading,
};

export default CHALLENGE_GETTERS;
