import { mapGetters, mapActions, mapMutations } from 'vuex';
import { FilterHead } from '@/components';
import { isNullOrWhiteSpace } from '@/utils';

export default {
  name: 'FilterType',
  components: {
    [FilterHead.name]: FilterHead,
  },
  props: {},
  data: () => ({
    filterHead: {
      icon: 'tag',
      text: 'Type',
    },
    filter: {
      isOpen: false,
      type: '',
    },
  }),
  computed: {
    ...mapGetters('event', {
      eventTypesList: 'getEventTypes',
      getEventFilter: 'getEventFilter',
    }),
  },
  methods: {
    ...mapActions('event', {
      initialiseEventTypes: 'FETCH_DATA_EVENT_TYPES',
    }),
    ...mapMutations('event', {
      setEventFilterType: 'SET_EVENT_FILTER_TYPE',
    }),
    openFilter() {
      this.filter.isOpen = !this.filter.isOpen;
    },
    updateFilterEventType(value) {
      this.filter.type = value.name;
      this.setEventFilterType(this.filter.type);
    },
    clearFilterEventType() {
      this.setEventFilterType('');
    },
  },
  watch: {
    getEventFilter: {
      handler() {
        if (isNullOrWhiteSpace(this.getEventFilter.type)) {
          this.filter.type = null;
        }
      },
      deep: true,
    },
  },
  created() {
    if (!isNullOrWhiteSpace(this.getEventFilter.type)) {
      this.filter.type = { text: this.getEventFilter.type };
    }

    this.initialiseEventTypes();
  },
};
