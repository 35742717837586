/* eslint-disable eqeqeq */
import { mapGetters, mapMutations } from 'vuex';
import {
  Tag,
} from '@/components';
import { isNullOrWhiteSpace } from '@/utils';

export default {
  name: 'SearchHeader',
  components: {
    Tag,
  },
  props: {
    title: '',
    searchPlaceholder: '',
    mapViewActive: false,
    resultCount: null,
    isClubSearch: false,
    isGroupSearch: false,
    hidefilters: false,
    hidelocation: false,
    query: '',
  },
  data: () => ({
    searchQuery: '',
    discipline: null,
  }),
  computed: {
    ...mapGetters('event', {
      getEventFilter: 'getEventFilter',
    }),
    ...mapGetters('club', {
      getClubFilter: 'getClubFilter',
    }),
    ...mapGetters('group', {
      getGroupFilter: 'getGroupFilter',
    }),
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
    resultCountText() {
      if (this.resultCount == 0) {
        return 'Geen resultaten gevonden.';
      } if (this.resultCount === 1) {
        return '1 resultaat gevonden.';
      }
      return `${this.resultCount} resultaten gevonden`;
    },
    tags() {
      const tagArray = [];
      let filter;

      if (this.isClubSearch) {
        filter = this.getClubFilter;
      } else if (this.isGroupSearch) {
        filter = this.getGroupFilter;
      } else {
        filter = this.getEventFilter;
      }

      if (typeof filter.age !== 'undefined'
        && filter.age !== null
        && filter.age.length !== null
        && filter.age.length > 0) {
        tagArray.push({ name: `${filter.age[0]}-${filter.age[1]} jaar`, type: 'age' });
      }

      if (typeof filter.availablePlaces !== 'undefined'
        && filter.availablePlaces !== null
        && filter.availablePlaces.length !== null
        && filter.availablePlaces.length > 0) {
        tagArray.push({ name: `${filter.availablePlaces[0]}-${filter.availablePlaces[1]} plaatsen`, type: 'availablePlaces' });
      }

      if (typeof filter.price !== 'undefined'
        && filter.price !== null
        && filter.price.length !== null
        && filter.price.length > 0) {
        tagArray.push({ name: `${filter.price[0]}-${filter.price[1]} euro`, type: 'price' });
      }

      if (typeof filter.date !== 'undefined'
      && filter.date !== null
      && filter.date.length !== null
      && filter.date.length > 0) {
        const dateFrom = new Date(filter.date[0]);
        const formattedDayFrom = this.$options.filters.formatDate(dateFrom);

        const dateTo = new Date(filter.date[1]);
        const formattedDayTo = this.$options.filters.formatDate(dateTo);

        tagArray.push({ name: `${formattedDayFrom} - ${formattedDayTo}`, type: 'date' });
      }

      if (typeof filter.tags !== 'undefined' && filter.tags !== null) {
        filter.tags.forEach((tag) => {
          tagArray.push({ name: `${tag}`, type: 'tag' });
        });
      }

      if (!isNullOrWhiteSpace(filter.type)) {
        tagArray.push({ name: filter.type, type: 'type' });
      }

      if (filter.discipline !== 0 && filter.discipline !== null) {
        this.discipline = this.disciplineList.find((d) => d.id === filter.discipline);
        tagArray.push({ name: this.discipline.name, type: 'discipline' });
      }

      if (filter.showStarted) {
        tagArray.push({ name: 'Toon gestarte activiteiten', type: 'showStarted' });
      }

      if (!isNullOrWhiteSpace(filter.location)) {
        tagArray.push({ name: filter.location, type: 'location', hiden: this.hidelocation });
      }

      return tagArray;
    },
  },
  methods: {
    ...mapMutations('event', {
      setEventFilterDate: 'SET_EVENT_FILTER_DATE',
      setEventFilterShowStarted: 'SET_EVENT_FILTER_SHOW_STARTED',
      setEventFilterAge: 'SET_EVENT_FILTER_AGE',
      setEventFilterAvailablePlaces: 'SET_EVENT_FILTER_AVAILABLE_PLACES',
      setEventFilterPrice: 'SET_EVENT_FILTER_PRICE',
      setEventFilterTags: 'SET_EVENT_FILTER_TAGS',
      setEventFilterType: 'SET_EVENT_FILTER_TYPE',
      setEventFilterDiscipline: 'SET_EVENT_FILTER_DISCIPLINE',
      setEventFilterLocation: 'SET_EVENT_FILTER_LOCATION',
    }),
    ...mapMutations('club', {
      setClubFilterDiscipline: 'SET_CLUB_FILTER_DISCIPLINE',
      setClubFilterLocation: 'SET_CLUB_FILTER_LOCATION',
    }),
    ...mapMutations('group', {
      setGroupFilterDiscipline: 'SET_GROUP_FILTER_DISCIPLINE',
      setGroupFilterLocation: 'SET_GROUP_FILTER_LOCATION',
    }),
    toggleMapView() {
      this.$emit('toggleMapView');
    },
    showMobileFilter() {
      this.$emit('toggleMobileFilter');
    },
    deleteTag(tag) {
      switch (tag.type) {
        case 'date':
          this.setEventFilterDate([]);
          break;
        case 'age':
          this.setEventFilterAge([]);
          break;
        case 'availablePlaces':
          this.setEventFilterAvailablePlaces([]);
          break;
        case 'price':
          this.setEventFilterPrice([]);
          break;
        case 'tag':
          this.setEventFilterTags(this.getEventFilter.tags.filter((filterTag) => filterTag !== tag.name));
          break;
        case 'type':
          this.setEventFilterType('');
          break;
        case 'discipline':
          if (this.isClubSearch) {
            this.setClubFilterDiscipline(null);
          } else if (this.isGroupSearch) {
            this.setGroupFilterDiscipline(null);
          } else {
            this.setEventFilterDiscipline(null);
          }
          break;
        case 'location':
          if (this.isClubSearch) {
            this.setClubFilterLocation(null);
          } else if (this.isGroupSearch) {
            this.setGroupFilterLocation(null);
          } else {
            this.setEventFilterLocation(null);
          }
          break;
        case 'showStarted':
          this.setEventFilterShowStarted(null);
          break;
        default:
          break;
      }

      this.search();
    },
    search() {
      this.$emit('searchQuery', this.searchQuery);
    },
  },
  created() {
    this.searchQuery = this.$route.query.q;
  },
  watch: {
    query() {
      if (this.query == '') {
        this.searchQuery = '';
      }
    },
  },
};
