export default {
  name: 'Activity',
  components: {},
  props: {
    event: {},
    popUpStyle: {},
  },
  data: () => ({
    mobileMap: false,
    error: false,
  }),
  computed: {
    activityStyle() {
      if (typeof this.popUpStyle !== 'undefined') {
        return this.showPopupInMap(this.$el, this.popUpStyle);
      }
      return {};
    },
    isFrame() {
      return this.$route.name.includes('frame');
    },
  },
  methods: {
    openEvent() {
      if (!this.isFrame) this.$router.push({ name: 'event-detail', params: { eventurl: this.event.eventUrl } });
      else {
        const routeData = this.$router.resolve({ name: 'event-detail', params: { eventurl: this.event.eventUrl } });
        window.open(routeData.href, '_blank');
      }
    },
    openMiddleClickEvent() {
      const routeData = this.$router.resolve({ name: 'event-detail', params: { eventurl: this.event.eventUrl } });
      window.open(routeData.href, '_blank');
    },
    showPopupInMap() {
      const popUpTop = this.popUpStyle.top.split('.')[0].replace(/[^0-9]/g, '');
      const popUpLeft = this.popUpStyle.left.split('.')[0].replace(/[^0-9]/g, '');

      const { mapWidth, mapHeight, windowWidth } = this.popUpStyle;

      if (windowWidth <= 480) {
        this.mobileMap = true;
        return {
          position: 'fixed', bottom: '1rem', left: '1.5rem', width: '92%',
        };
      }

      if (windowWidth <= 768) {
        this.mobileMap = true;
        return {
          position: 'fixed', bottom: '1rem', right: '1.5rem', width: 'unset',
        };
      }

      const longEnough = mapHeight - popUpTop - 325 > 0;
      const bigEnough = mapWidth - popUpLeft - 307 > 0;

      const styleObj = { width: 'unset' };

      if (longEnough && bigEnough) {
        styleObj.top = this.popUpStyle.top;
        styleObj.left = this.popUpStyle.left;
        return styleObj;
      }

      if (!longEnough && bigEnough) {
        styleObj.bottom = `${mapHeight - popUpTop}px`;
        styleObj.left = this.popUpStyle.left;
        return styleObj;
      }

      if (longEnough && !bigEnough) {
        styleObj.top = this.popUpStyle.top;
        styleObj.right = `${mapWidth - popUpLeft}px`;
        return styleObj;
      }

      styleObj.bottom = `${mapHeight - popUpTop}px`;
      styleObj.right = `${mapWidth - popUpLeft}px`;
      return styleObj;
    },

    clearPopup(event) {
      if (event) {
        event.preventDefault();
      }
      this.$emit('closePopup');
    },

    clickOutside(event) {
      if (this.mobileMap) {
        this.clearPopup(event);
      }
    },
  },
};
