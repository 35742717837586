export default {
  name: 'Footer',
  components: {},
  props: {},
  data: () => ({
    urlConditions: process.env.VUE_APP_URL_CONDITIONS,
    urlPolicy: process.env.VUE_APP_URL_POLICY,
    urlCookiePolicy: process.env.VUE_APP_URL_COOKIE_POLICY,
  }),
  computed: {
    isFrame() {
      return this.$route.name.includes('frame');
    },
  },
  methods: {},
};
