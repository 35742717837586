export default {
  name: 'Tag',
  components: {},
  props: {
    tag: null,
    showClose: Boolean,
  },
  data: () => ({
  }),
  computed: {},
  methods: {
    deleteTag() {
      this.$emit('deleteTag');
    },
  },
};
