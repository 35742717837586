import {
  mapGetters,
} from 'vuex';
import isServer from '@/utils/isServer';

export default {
  computed: {
    ...mapGetters('account', {
      token: 'getToken',
      serverToken: 'getServerToken',
      currentUser: 'getCurrentUser',
      serverUser: 'getServerUser',
    }),
    $currentUser() {
      return this.currentUser || this.serverUser;
    },
    $isLoggedIn() {
      return this.token !== '' || this.serverToken !== '';
    },
    $isAdmin() {
      const user = isServer && this.serverUser !== '' ? JSON.parse(this.serverUser) : this.currentUser;
      if (user) {
        return !!(user.roles.includes('Admin') || user.roles.includes('SuperAdmin'));
      }
      return false;
    },
    $isSuperAdmin() {
      const user = isServer && this.serverUser !== '' ? JSON.parse(this.serverUser) : this.currentUser;
      if (user) {
        return !!(user.roles.includes('SuperAdmin'));
      }
      return false;
    },
  },
  methods: {
  },
};
