import ACTIVITY_MUTATION_TYPES from './activity.mutation-types';
import initialState from './activity.initial-state';

const ACTIVITY_MUTATIONS = {
  [ACTIVITY_MUTATION_TYPES.APPEND_USER_ACTIVITY_LIST](state, activities) {
    state.userActivities.currentPage = activities.currentPage;
    state.userActivities.pageCount = activities.pageCount;
    state.userActivities.rowCount = activities.rowCount;
    state.userActivities.firstRowOnPage = activities.firstRowOnPage;
    state.userActivities.lastRowOnPage = activities.lastRowOnPage;
    state.userActivities.results.push(...activities.results);
  },
  [ACTIVITY_MUTATION_TYPES.SET_ACTIVITY](state, ACTIVITY) {
    state.userActivity = ACTIVITY;
  },
  [ACTIVITY_MUTATION_TYPES.SET_ERROR](state, error) {
    state.status.loading = false;
    state.status.error = error;
  },
  [ACTIVITY_MUTATION_TYPES.CLEAR_USER_ACTIVITY_LIST](state) {
    state.userActivities = initialState().userActivities;
  },
  [ACTIVITY_MUTATION_TYPES.SET_LOADING_STATE](state, isLoading) {
    state.status.loading = isLoading;
  },
  [ACTIVITY_MUTATION_TYPES.SET_BUTTON_LOADING_STATE](state, isLoading) {
    state.buttonLoading = isLoading;
  },
  [ACTIVITY_MUTATION_TYPES.SET_ACTIVITY_ID](state, activityId) {
    state.activityId = activityId;
  },
};

export default ACTIVITY_MUTATIONS;
