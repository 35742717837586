import {
  Activity,
} from '@/components';

export default {
  name: 'Activities',
  components: {
    [Activity.name]: Activity,
  },
  props: {},
  data: () => ({
  }),
  computed: {},
  methods: {},
};
