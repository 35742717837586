import moment from 'moment';
import Vue from 'vue';

/* ==========================================================================
   FILTERS
   ========================================================================== */
Vue.filter('formatDate', (value, format = 'DD/MM/YYYY') => {
  if (value) {
    return moment(value).format(format);
  }
  return '';
});

Vue.filter('formatDateShort', (value, format = 'DD/MM/YY') => {
  if (value) {
    return moment(value).format(format);
  }
  return '';
});

Vue.filter('formatDay', (value, format = 'DD') => {
  if (value) {
    return moment(value).format(format);
  }
  return '';
});

Vue.filter('formatShortMonth', (value, format = 'MMM') => {
  if (value) {
    return moment(value).format(format);
  }
  return '';
});

Vue.filter('formatDateTime', (value, format = 'DD/MM/YYYY HH:mm') => {
  if (value) {
    return moment(value).format(format);
  }
  return '';
});

Vue.filter('formatHourAndMinutes', (value, format = 'HH:mm') => {
  if (value) {
    return moment(value).format(format);
  }
  return '';
});

Vue.filter('striphtml', (value) => {
  const div = document.createElement('div');
  div.innerHTML = value;
  const text = div.textContent || div.innerText || '';
  return text;
});
