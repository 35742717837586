import ACCOUNT_INITIAL_STATE from './account.initial-state';
import ACCOUNT_MUTATIONS from './account.mutations';
import ACCOUNT_GETTERS from './account.getters';
import ACCOUNT_ACTIONS from './account.actions';

/* ==========================================================================
   ACCOUNT MODULE STORE
   ========================================================================== */
const accountStore = {
  name: 'account',
  namespaced: true,
  state: () => ACCOUNT_INITIAL_STATE(),
  mutations: ACCOUNT_MUTATIONS,
  actions: ACCOUNT_ACTIONS,
  getters: ACCOUNT_GETTERS,
};

// eslint-disable-next-line import/prefer-default-export
export { accountStore };
