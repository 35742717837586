export default () => ({
  advertisements: {
    currentPage: null,
    pageCount: null,
    rowCount: null,
    firstRowOnPage: null,
    lastRowOnPage: null,
    results: [],
  },
  advertisement: null,
  advertisementBestmatch: null,
  buttonLoading: false,
  page: 1,
  status: {
    loading: false,
    error: '',
  },
});
