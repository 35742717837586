import { mapGetters, mapMutations } from 'vuex';
import { FilterHead } from '@/components';

export default {
  name: 'FilterDiscipline',
  components: {
    [FilterHead.name]: FilterHead,
  },
  props: { isClubSearch: false, isGroupSearch: false },
  data: () => ({
    filterHead: {
      icon: 'water',
      text: 'Sporttak',
    },
    filter: {
      isOpen: false,
      discipline: '',
    },
    window: {
      width: 0,
      height: 0,
    },
    isMobileSearch: false,
  }),
  computed: {
    ...mapGetters('event', {
      getEventFilter: 'getEventFilter',
    }),
    ...mapGetters('club', {
      getClubFilter: 'getClubFilter',
    }),
    ...mapGetters('group', {
      getGroupFilter: 'getGroupFilter',
    }),
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
    disciplines() {
      if (!this.isClubSearch && !this.isGroupSearch) return this.disciplineList;
      return this.disciplineList.filter((d) => d.level === 0);
    },
  },
  methods: {
    ...mapMutations('event', {
      setEventFilterDiscipline: 'SET_EVENT_FILTER_DISCIPLINE',
    }),
    ...mapMutations('club', {
      setClubFilterDiscipline: 'SET_CLUB_FILTER_DISCIPLINE',
    }),
    ...mapMutations('group', {
      setGroupFilterDiscipline: 'SET_GROUP_FILTER_DISCIPLINE',
    }),
    openFilter() {
      this.filter.isOpen = !this.filter.isOpen;
    },
    updateFilterDiscipline(discipline) {
      if (this.filter.discipline === discipline.id) {
        this.filter.discipline = null;
      } else {
        this.filter.discipline = discipline.id;
      }
      if (this.isClubSearch) {
        this.setClubFilterDiscipline(this.filter.discipline);
      } else if (this.isGroupSearch) {
        this.setGroupFilterDiscipline(this.filter.discipline);
      } else {
        this.setEventFilterDiscipline(this.filter.discipline);
      }

      this.openFilter();
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.isMobileSearch = false;

      if (this.window.width <= 768) {
        this.isMobileSearch = true;
      }
    },
  },
  watch: {
    getEventFilter: {
      handler() {
        this.filter.discipline = this.getEventFilter.discipline;

        if (this.getEventFilter.discipline === 0 || this.getEventFilter.discipline === null) {
          this.filter.discipline = null;
        }
      },
      deep: true,
    },
    getClubFilter: {
      handler() {
        this.filter.discipline = this.getClubFilter.discipline;

        if (this.getClubFilter.discipline === 0 || this.getClubFilter.discipline === null) {
          this.filter.discipline = null;
        }
      },
      deep: true,
    },
    getGroupFilter: {
      handler() {
        this.filter.discipline = this.getGroupFilter.discipline;

        if (this.getGroupFilter.discipline === 0 || this.getGroupFilter.discipline === null) {
          this.filter.discipline = null;
        }
      },
      deep: true,
    },
  },
  created() {
    // document.addEventListener('resize', this.handleResize);
    // this.handleResize();
  },
};
