/* eslint-disable no-unused-vars */

import { api } from '@/utils';
import ACTIVITY_MUTATION_TYPES from './activity.mutation-types';

const ACTIVITY_ACTIONS = {
  [ACTIVITY_MUTATION_TYPES.FETCH_DATA_USER_ACTIVITY_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(ACTIVITY_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(ACTIVITY_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('user/activities', params)
        .then((response) => {
          commit(ACTIVITY_MUTATION_TYPES.APPEND_USER_ACTIVITY_LIST, response.data);
          commit(ACTIVITY_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(ACTIVITY_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        }).catch((error) => {
          commit(ACTIVITY_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(ACTIVITY_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [ACTIVITY_MUTATION_TYPES.FETCH_DATA_EDIT_ACTIVITY]({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit(ACTIVITY_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get(`activity/${id}`)
        .then((response) => {
          const activity = response.data;
          commit(ACTIVITY_MUTATION_TYPES.SET_ACTIVITY, activity);
          commit(ACTIVITY_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(ACTIVITY_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [ACTIVITY_MUTATION_TYPES.CREATE_ACTIVITY]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.post('activity', params)
        .then((response) => {
          commit(ACTIVITY_MUTATION_TYPES.SET_ACTIVITY_ID, response.data);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [ACTIVITY_MUTATION_TYPES.EDIT_ACTIVITY]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.put(`activity/${params.id}`, params)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [ACTIVITY_MUTATION_TYPES.DELETE_ACTIVITY]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.delete(`activity/${params.activityId}`, params)
        .then((response) => {
          commit(ACTIVITY_MUTATION_TYPES.SET_ACTIVITY_ID, response.data);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
};

export default ACTIVITY_ACTIONS;
