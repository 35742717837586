import cookies from 'vue-cookies';
import ACCOUNT_MUTATION_TYPES from './account.mutation-types';
import LocalStorage from '../../utils/localStorageMock';

const ACCOUNT_MUTATIONS = {
  [ACCOUNT_MUTATION_TYPES.SET_TOKEN](state, token) {
    const now = new Date();

    const item = {
      token,
      expiry: now.getTime() + 86400000,
    };

    LocalStorage.setItem('token', JSON.stringify(item));
    cookies.set('token', JSON.stringify(item));
    state.token = token;
  },
  [ACCOUNT_MUTATION_TYPES.SET_LOADING_STATE](state, isLoading) {
    state.status.loading = isLoading;
  },
  [ACCOUNT_MUTATION_TYPES.SET_RESET_PASSORD_LOADING_STATE](state, isLoading) {
    state.status.resetPasswordLoading = isLoading;
  },
  [ACCOUNT_MUTATION_TYPES.SET_FORGOT_PASSORD_LOADING_STATE](state, isLoading) {
    state.status.forgotPasswordLoading = isLoading;
  },
  [ACCOUNT_MUTATION_TYPES.SET_CURRENT_USER](state, user) {
    LocalStorage.setItem('user', JSON.stringify(user));
    cookies.set('user', JSON.stringify(user));
    state.currentUser = user;
  },
  [ACCOUNT_MUTATION_TYPES.SET_PROFILE_PICTURE](state, image) {
    const curr = JSON.parse(LocalStorage.getItem('user'));
    curr.image = image;
    LocalStorage.setItem('user', JSON.stringify(curr));
    state.currentUser = curr;
  },
  [ACCOUNT_MUTATION_TYPES.SET_ERROR](state, error) {
    state.status.loading = false;
    state.status.error = error;
  },
  [ACCOUNT_MUTATION_TYPES.SET_SUCCESSFUL_REGISTER](state) {
    state.status.loading = false;
    state.registerSuccessful = true;
  },
  [ACCOUNT_MUTATION_TYPES.REMOVE_TOKEN](state) {
    LocalStorage.removeItem('token');
    LocalStorage.removeItem('user');
    cookies.remove('token');
    cookies.remove('user');
    state.token = '';
    state.serverToken = '';
    state.serverUser = '';

    state.currentUser = null;
  },
  [ACCOUNT_MUTATION_TYPES.SET_SERVER_TOKEN](state, token) {
    state.serverToken = token;
  },
  [ACCOUNT_MUTATION_TYPES.SET_SERVER_USER](state, user) {
    state.serverUser = user;
  },
};

export default ACCOUNT_MUTATIONS;
