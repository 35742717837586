/* eslint-disable no-unused-vars */
import {
  mapActions, mapGetters,
} from 'vuex';

import isServer from '@/utils/isServer';

if (!isServer) {
  var { Vuetable, VuetablePagination } = require('vuetable-2');
  var fileDownload = require('js-file-download');
}

export default {
  name: 'EventParticipantsAdmin',
  components: {
    Vuetable,
    VuetablePagination,
  },
  props: {
    tableData: {
      activeParticipants: [],
      inActiveParticipants: [],
    },
    eventId: null,
    availablePlaces: null,
  },
  data: () => ({
    tableFields: [
      {
        name: '__slot:number',
        title: 'Nummer',
      },
      {
        name: '__slot:participant',
        title: 'Deelnemer',
      },
      {
        name: '__slot:joinDate',
        title: 'Datum inschrijving',
      },
      {
        name: '__slot:birthDate',
        title: 'Datum geboorte',
      },
      {
        name: 'paymentStatus',
        title: 'Mollie Status',
      },
      {
        name: 'email',
        title: 'Email',
      },
      {
        name: '__slot:hasPaid',
        title: 'Betaald',
      },
      {
        name: '__slot:actions',
        title: 'Actie',
      },
    ],
    inActiveTableFields: [
      {
        name: '__slot:number',
        title: 'Nummer',
      },
      {
        name: '__slot:participant',
        title: 'Deelnemer',
      },
      {
        name: '__slot:joinDate',
        title: 'Datum inschrijving',
      },
      {
        name: '__slot:birthDate',
        title: 'Datum geboorte',
      },
      {
        name: 'paymentStatus',
        title: 'Mollie Status',
      },
      {
        name: 'email',
        title: 'Email',
      },
      {
        name: '__slot:hasPaid',
        title: 'Betaald',
      },
    ],
    participant: null,
    searchPlaceholder: 'Zoek een deelnemer...',
    searchQuery: '',
    searchQueryPast: '',
  }),
  computed: {
    isAdmin() {
      return !!(this.$isAdmin || this.event.currentUserIsAdmin);
    },
  },
  methods: {
    ...mapActions('event', {
      toggleParticipantHasPaid: 'UPDATE_EVENT_PARTICIPANT',
      removeParticipant: 'REMOVE_PARTICIPANT',
      createParticipantsCsv: 'CREATE_PARTICIPANTS_CSV',
      createCancelledParticipantsCsv: 'CREATE_CANCELLED_PARTICIPANTS_CSV',
      fetchEventParticipants: 'FETCH_DATA_EVENT_PARTICIPANTS_LIST',
    }),
    isActiveData() {
      return this.tableData.activeParticipants.results;
    },
    isInActiveData() {
      return this.tableData.inActiveParticipants.results;
    },
    exportParticipants() {
      const params = {
        eventId: this.eventId,
      };

      this.createParticipantsCsv(params).then((response) => {
        fileDownload(response.data, 'deelnemers-evenement.csv');
      });
    },
    exportCancelledParticipants() {
      const params = {
        eventId: this.eventId,
      };

      this.createCancelledParticipantsCsv(params).then((response) => {
        fileDownload(response.data, 'geannuleerde-deelnemers-evenement.csv');
      });
    },
    participantPaidToggle(event, rowData) {
      const paramsObj = {
        eventId: this.eventId,
        participantId: rowData.id,
        hasPaid: false,
      };

      if (event.target.checked) {
        paramsObj.hasPaid = true;
      }

      const toastMessage = paramsObj.hasPaid ? `${rowData.firstname} ${rowData.lastname} heeft betaald.` : `${rowData.firstname} ${rowData.lastname} heeft niet betaald.`;
      this.toggleParticipantHasPaid(paramsObj).then(() => {
        this.$emit('refreshList');
        this.$toasted.show(toastMessage, { type: 'success' });
      }).catch((error) => {
        this.$toasted.show(...error.response.data);
      });
    },
    onDelete(event, rowData) {
      this.$emit('showConfirmationModalFunction', rowData);
    },
    search() {
      const searchQueries = {
        searchQuery: '',
        searchQueryPast: '',
      };
      searchQueries.searchQuery = this.searchQuery;
      searchQueries.searchQueryPast = this.searchQueryPast;
      this.$emit('refreshParticipants', searchQueries);
    },
  },
  watch: {
    tableData() {
      this.$refs.vuetable.refresh();
      this.$refs.inActivevuetable.refresh();
    },
  },
};
