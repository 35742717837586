import { render, staticRenderFns } from "./EventParticipantsAdmin.vue?vue&type=template&id=ed78d9ec&"
import script from "./EventParticipantsAdmin.controller.js?vue&type=script&lang=js&"
export * from "./EventParticipantsAdmin.controller.js?vue&type=script&lang=js&"
import style0 from "./EventParticipantsAdmin.styles.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports