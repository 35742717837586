/* eslint-disable no-unused-vars */
import { api } from '@/utils';
import GROUP_MUTATION_TYPES from './group.mutation-types';

const GROUP_ACTIONS = {
  [GROUP_MUTATION_TYPES.FETCH_DATA_GROUP_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(GROUP_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(GROUP_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('group/get', params)
        .then((response) => {
          commit(GROUP_MUTATION_TYPES.APPEND_GROUP_LIST, response.data);
          commit(GROUP_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(GROUP_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(GROUP_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(GROUP_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.FETCH_DATA_USER_GROUP_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.loadButton) {
        commit(GROUP_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, true);
      } else {
        commit(GROUP_MUTATION_TYPES.SET_LOADING_STATE, true);
      }
      api.post('user/groups', params)
        .then((response) => {
          commit(GROUP_MUTATION_TYPES.APPEND_USER_GROUP_LIST, response.data);
          commit(GROUP_MUTATION_TYPES.SET_LOADING_STATE, false);
          commit(GROUP_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          resolve();
        }).catch((error) => {
          console.log(error);
          commit(GROUP_MUTATION_TYPES.SET_BUTTON_LOADING_STATE, false);
          commit(GROUP_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.FETCH_DATA_GROUP_MARKERS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(GROUP_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.post('group/get', params)
        .then((response) => {
          commit(GROUP_MUTATION_TYPES.SET_GROUP_MARKERS, response.data);
          commit(GROUP_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(GROUP_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.ADD_GROUP_ADMIN]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.post(`group/${params.groupId}/addadmin`, params)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.REMOVE_GROUP_ADMIN]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.post(`group/${params.groupId}/removeadmin`, params)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.REMOVE_GROUP_FOLLOWER]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.post('group/removeFollower', params).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  [GROUP_MUTATION_TYPES.FETCH_DATA_GROUP_FOLLOWERS_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(GROUP_MUTATION_TYPES.SET_FOLLOWERS_LOADING_STATE, true);
      let url = '';

      if (params.isAdmin) {
        url = `group/${params.groupId}/admin/followers?`;
      } else {
        url = `group/${params.groupId}/followers?`;
      }
      if (params.page) {
        url += `page=${params.page}&`;
      }
      if (params.pageSize) {
        url += `pageSize=${params.pageSize}`;
      }

      api.get(url)
        .then((response) => {
          const groupFollowersList = response.data.results;
          commit(GROUP_MUTATION_TYPES.APPEND_GROUP_FOLLOWERS_LIST, groupFollowersList);
          commit(GROUP_MUTATION_TYPES.SET_FOLLOWERS_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(GROUP_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.CREATE_GROUP]({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const formData = new FormData();
      formData.append('Name', params.name);
      formData.append('Abbreviation', params.abbreviation);
      formData.append('ContactPerson', params.contactPerson);
      formData.append('Address_Street', params.address_Street);
      formData.append('Address_Nr', params.address_Nr);
      formData.append('Address_Postcode', params.address_Postcode);
      formData.append('Address_City', params.address_City);
      formData.append('Address_Country', params.address_Country);
      formData.append('Address_Province', params.address_Province);
      formData.append('ContactEmail', params.contactEmail);
      formData.append('ContactPhone', params.contactPhone);
      formData.append('GroupURL', params.groupUrl);
      formData.append('Logo', params.logo);
      formData.append('Lat', params.lat);
      formData.append('Long', params.long);
      formData.append('AboutUs', params.aboutUs);
      formData.append('Image', params.image);
      formData.append('CoverImage', params.coverImage);

      let i = 0;
      params.groupDisciplines.forEach((discipline) => {
        formData.append(`GroupDisciplines[${i}]`, discipline);
        i += 1;
      });

      let t = 0;
      if (params.tags !== undefined) {
        params.tags.forEach((tag) => {
          formData.append(`Tags[${t}]`, tag.text);
          t += 1;
        });
      }

      api.post('group', formData, config)
        .then((response) => {
          commit(GROUP_MUTATION_TYPES.SET_GROUP_ID, response.data);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.EDIT_GROUP]({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      const formData = new FormData();
      formData.append('Name', params.name);
      formData.append('Abbreviation', params.abbreviation);
      formData.append('ContactPerson', params.contactPerson);
      formData.append('Address_Street', params.address_Street);
      formData.append('Address_Nr', params.address_Nr);
      formData.append('Address_Postcode', params.address_Postcode);
      formData.append('Address_City', params.address_City);
      formData.append('Address_Country', params.address_Country);
      formData.append('Address_Province', params.address_Province);
      formData.append('ContactEmail', params.contactEmail);
      formData.append('ContactPhone', params.contactPhone);
      formData.append('GroupURL', params.groupUrl);
      formData.append('Logo', params.logo);
      formData.append('Lat', params.lat);
      formData.append('Long', params.long);
      formData.append('AboutUs', params.aboutUs);
      formData.append('Image', params.image);
      formData.append('CoverImage', params.coverImage);

      let i = 0;
      params.groupDisciplines.forEach((discipline) => {
        formData.append(`GroupDisciplines[${i}]`, discipline);
        i += 1;
      });

      let t = 0;
      if (params.tags !== undefined) {
        params.tags.forEach((tag) => {
          formData.append(`Tags[${t}]`, tag.text);
          t += 1;
        });
      }
      api.put(`group/${params.id}`, formData, config)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.FETCH_DATA_EDIT_GROUP]({ commit }, url) {
    return new Promise((resolve, reject) => {
      commit(GROUP_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get(`group/edit/${url}`)
        .then((response) => {
          const group = response.data;
          commit(GROUP_MUTATION_TYPES.SET_GROUP, group);
          commit(GROUP_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(GROUP_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.FOLLOW_GROUP]({ commit }, groupId) {
    return new Promise((resolve, reject) => {
      api.post(`group/${groupId}/follow`)
        .then((response) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.UNFOLLOW_GROUP]({ commit }, groupId) {
    return new Promise((resolve, reject) => {
      api.post(`group/${groupId}/unfollow`)
        .then((response) => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
    });
  },
  [GROUP_MUTATION_TYPES.FETCH_DATA_GROUP]({ commit }, url) {
    return new Promise((resolve, reject) => {
      commit(GROUP_MUTATION_TYPES.SET_LOADING_STATE, true);
      api.get(`group/${url}`)
        .then((response) => {
          const group = response.data;
          commit(GROUP_MUTATION_TYPES.SET_GROUP, group);
          commit(GROUP_MUTATION_TYPES.SET_LOADING_STATE, false);
          resolve();
        })
        .catch((error) => {
          commit(GROUP_MUTATION_TYPES.SET_ERROR, error);
          reject(error);
        });
    });
  },
};

export default GROUP_ACTIONS;
