const CLUB_GETTERS = {
  getClubList: (state) => state.clubs,
  getUserClubList: (state) => state.userClubs,
  getClub: (state) => state.club,
  getClubFollowers: (state) => state.clubFollowers,
  getLoadingState: (state) => state.status.loading,
  getButtonLoadingState: (state) => state.buttonLoading,
  getClubMarkers: (state) => state.markers,
  getClubFilter: (state) => state.clubFilter,
  getUserClubFilter: (state) => state.userClubFilter,
  getClubEvents: (state) => state.clubEvents,
  getPastClubEvents: (state) => state.pastClubEvents,
  getClubId: (state) => state.clubId,
};

export default CLUB_GETTERS;
