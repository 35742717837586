const ACTIVITY_MUTATION_TYPES = Object.freeze({
  FETCH_DATA_USER_ACTIVITY_LIST: 'FETCH_DATA_USER_ACTIVITY_LIST',
  FETCH_DATA_EDIT_ACTIVITY: 'FETCH_DATA_EDIT_ACTIVITY',
  APPEND_USER_ACTIVITY_LIST: 'APPEND_USER_ACTIVITY_LIST',
  CLEAR_USER_ACTIVITY_LIST: 'CLEAR_USER_ACTIVITY_LIST',

  CREATE_ACTIVITY: 'CREATE_ACTIVITY',
  EDIT_ACTIVITY: 'EDIT_ACTIVITY',
  DELETE_ACTIVITY: 'DELETE_ACTIVITY',
  SET_ACTIVITY_ID: 'SET_ACTIVITY_ID',

  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_ERROR: 'SET_ERROR',
  SET_BUTTON_LOADING_STATE: 'SET_BUTTON_LOADING_STATE',
  SET_ACTIVITY: 'SET_ACTIVITY',
});

export default ACTIVITY_MUTATION_TYPES;
