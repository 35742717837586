import { mapMutations, mapGetters } from 'vuex';
import _ from 'lodash';
import { FilterHead } from '@/components';
import 'vue-slider-component/theme/default.css';
import isServer from '@/utils/isServer';

if (!isServer) {
  var VueSlider = require('vue-slider-component');
}
export default {
  name: 'FilterPrice',
  components: {
    [FilterHead.name]: FilterHead,
    VueSlider,
  },
  props: {},
  data: () => ({
    filterHead: {
      icon: 'euro-sign',
      text: 'Prijs',
    },
    filter: {
      isOpen: false,
      price: null,
      marks: [0, 1500],
      marksvalue: [0, 1500],
    },
  }),
  computed: {
    ...mapGetters('event', {
      getEventFilter: 'getEventFilter',
    }),
    marks: {
      get() {
        if (this.getEventFilter.price.length === 0) {
          this.filter.marks = [0, 1500];
          return this.filter.marks;
        }
        this.filter.marks = this.getEventFilter.price;
        return this.filter.marks;
      },
      // eslint-disable-next-line func-names
      set: _.debounce(function (value) {
        this.filter.marks = value;
        this.filter.price = value;

        if (this.filter.price[0] === 0 && this.filter.price[1] === 1500) {
          this.filter.price = [];
          this.filter.marks = [];
        }
        this.setEventFilterPrice(this.filter.price);
      }, 600),
    },
  },
  methods: {
    ...mapMutations('event', {
      setEventFilterPrice: 'SET_EVENT_FILTER_PRICE',
    }),
    openFilter() {
      this.filter.isOpen = !this.filter.isOpen;
    },
    onDrag(value) {
      this.$set(this.filter, 'marksvalue', value);
    },
  },
  watch: {
    getEventFilter: {
      handler(updatedEventFilter) {
        if (updatedEventFilter.price.length) this.$set(this.filter, 'marksvalue', updatedEventFilter.price);
        else this.$set(this.filter, 'marksvalue', [0, 1500]);
      },
      deep: true,
    },
  },
};
