const GROUP_MUTATION_TYPES = Object.freeze({
  FETCH_DATA_GROUP_LIST: 'FETCH_DATA_GROUP_LIST',
  FETCH_DATA_USER_GROUP_LIST: 'FETCH_DATA_USER_GROUP_LIST',
  FETCH_DATA_GROUP: 'FETCH_DATA_GROUP',
  FETCH_DATA_GROUP_FOLLOWERS_LIST: 'FETCH_DATA_GROUP_FOLLOWERS_LIST',
  FETCH_DATA_EDIT_GROUP: 'FETCH_DATA_EDIT_GROUP',
  FETCH_DATA_GROUP_MARKERS: 'FETCH_DATA_GROUP_MARKERS',

  APPEND_GROUP_LIST: 'APPEND_GROUP_LIST',
  APPEND_USER_GROUP_LIST: 'APPEND_USER_GROUP_LIST',
  APPEND_GROUP_FOLLOWERS_LIST: 'APPEND_GROUP_FOLLOWERS_LIST',

  ADD_GROUP_ADMIN: 'ADD_GROUP_ADMIN',
  REMOVE_GROUP_ADMIN: 'REMOVE_GROUP_ADMIN',
  REMOVE_GROUP_FOLLOWER: 'REMOVE_GROUP_FOLLOWER',
  FOLLOW_GROUP: 'FOLLOW_GROUP',
  UNFOLLOW_GROUP: 'UNFOLLOW_GROUP',

  SET_ERROR: 'SET_ERROR',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  SET_FOLLOWERS_LOADING_STATE: 'SET_FOLLOWERS_LOADING_STATE',
  SET_BUTTON_LOADING_STATE: 'SET_BUTTON_LOADING_STATE',

  CREATE_GROUP: 'CREATE_GROUP',
  EDIT_GROUP: 'EDIT_GROUP',

  SET_GROUP_FILTER_DISCIPLINE: 'SET_GROUP_FILTER_DISCIPLINE',
  SET_GROUP_FILTER_LOCATION: 'SET_GROUP_FILTER_LOCATION',
  SET_GROUP_FILTER_QUERY: 'SET_GROUP_FILTER_QUERY',
  CLEAR_GROUP_FILTER_QUERY: 'CLEAR_GROUP_FILTER_QUERY',
  SET_GROUP_MARKERS: 'SET_GROUP_MARKERS',
  SET_GROUP: 'SET_GROUP',
  SET_GROUP_ID: 'SET_GROUP_ID',
  CLEAR_GROUP_LIST: 'CLEAR_GROUP_LIST',
  CLEAR_USER_GROUP_LIST: 'CLEAR_USER_GROUP_LIST',
  CLEAR_GROUP_FOLLOWERS_LIST: 'CLEAR_GROUP_FOLLOWERS_LIST',
});

export default GROUP_MUTATION_TYPES;
