import { mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import { FilterHead } from '@/components';
import isServer from '@/utils/isServer';

if (!isServer) {
  var VueSlider = require('vue-slider-component');
  require('vue-slider-component/theme/default.css');
}
export default {
  name: 'FilterAge',
  components: {
    [FilterHead.name]: FilterHead,
    VueSlider,
  },
  props: {},
  data: () => ({
    filterHead: {
      icon: 'user',
      text: 'Leeftijd',
    },
    filter: {
      isOpen: false,
      age: null,
      marks: [0, 100],
      marksvalue: [0, 100],
    },
  }),
  computed: {
    ...mapGetters('event', {
      getEventFilter: 'getEventFilter',
    }),
    marks: {
      get() {
        if (this.getEventFilter.age.length === 0) {
          this.filter.marks = [0, 100];
          return this.filter.marks;
        }
        this.filter.marks = this.getEventFilter.age;
        return this.filter.marks;
      },
      // eslint-disable-next-line func-names
      set: _.debounce(function (value) {
        this.filter.marks = value;
        this.filter.age = value;

        if (this.filter.age[0] === 0 && this.filter.age[1] === 100) {
          this.filter.age = [];
          this.filter.marks = [];
        }
        this.setEventFilterAge(this.filter.age);
      }, 600),
    },
  },
  methods: {
    ...mapMutations('event', {
      setEventFilterAge: 'SET_EVENT_FILTER_AGE',
    }),
    openFilter() {
      this.filter.isOpen = !this.filter.isOpen;
    },
    onDrag(value) {
      this.$set(this.filter, 'marksvalue', value);
    },
  },
  watch: {
    getEventFilter: {
      handler(updatedEventFilter) {
        if (updatedEventFilter.age.length) this.$set(this.filter, 'marksvalue', updatedEventFilter.age);
        else this.$set(this.filter, 'marksvalue', [0, 100]);
      },
      deep: true,
    },
  },
};
