export default {
  name: 'ShareButton',
  components: {
  },
  props: {
    shareLink: {
      iconLogo: '',
      externalLinkClass: '',
    },
  },
  data: () => ({
  }),
  computed: {
    link() {
      switch (this.shareLink.externalLinkClass) {
        case 'facebook':
          return `https://www.facebook.com/sharer/sharer.php?u=${window.location.protocol}//${window.location.hostname}${this.$route.path}`;
        case 'twitter':
          return `http://twitter.com/share?text=Ikwatersport&url=${window.location.protocol}//${window.location.hostname}${this.$route.path}`;
        default:
          return '';
      }
    },
  },
  methods: {
  },
};
