import EVENT_INITIAL_STATE from './event.initial-state';
import EVENT_MUTATIONS from './event.mutations';
import EVENT_GETTERS from './event.getters';
import EVENT_ACTIONS from './event.actions';

/* ==========================================================================
   EVENT MODULE STORE
   ========================================================================== */
const eventStore = {
  name: 'event',
  namespaced: true,
  state: () => EVENT_INITIAL_STATE(),
  mutations: EVENT_MUTATIONS,
  actions: EVENT_ACTIONS,
  getters: EVENT_GETTERS,
};

// eslint-disable-next-line import/prefer-default-export
export { eventStore };
