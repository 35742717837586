import _ from 'lodash';
import storageMock from '../../utils/localStorageMock';

export default () => {
  const data = ({
    token: _.get(JSON.parse(storageMock.getItem('token')), 'token', ''),
    serverToken: '',
    serverUser: '',
    currentUser: JSON.parse(storageMock.getItem('user')) || null,
    status: {
      loading: false,
      resetPasswordLoading: false,
      forgotPasswordLoading: false,
      error: '',
    },
    registerSuccessful: false,
  });

  console.log(JSON.stringify(data, null, 2));

  return data;
};
