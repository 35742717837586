/* eslint-disable import/no-dynamic-require */

export default {
  name: 'InterestCard',
  components: {
  },
  props: {
    interest: {},
    isActive: false,
  },
  data: () => ({
    active: false,
  }),
  computed: {
    imageUrl() {
      if (this.isActive) {
        const path = `/images/generic/interest/${this.interest.name.toLowerCase()}--dark.svg`;
        return require(`@/assets${path}`);
      }
      const path = `/images/generic/interest/${this.interest.name.toLowerCase()}--white.svg`;
      return require(`@/assets${path}`);
    },
  },
  methods: {
    toggleActiveState() {
      this.active = !this.isActive;
      if (this.active) {
        this.$emit('addInterestId', this.interest.id);
      } else {
        this.$emit('removeInterestId', this.interest.id);
      }
    },
  },
};
