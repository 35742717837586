import { mapGetters, mapMutations } from 'vuex';
import { isNullOrWhiteSpace } from '@/utils';
import {
  FilterDate,
  FilterAge,
  FilterAvailablePlaces,
  FilterPrice,
  FilterTags,
  FilterType,
  FilterDiscipline,
  FilterLocation,
  Tag,
  Loader,
  Advertisement,
} from '@/components';

export default {
  name: 'Sidebar',
  components: {
    [FilterDate.name]: FilterDate,
    [FilterAge.name]: FilterAge,
    [FilterAvailablePlaces.name]: FilterAvailablePlaces,
    [FilterPrice.name]: FilterPrice,
    [FilterTags.name]: FilterTags,
    [FilterType.name]: FilterType,
    [FilterDiscipline.name]: FilterDiscipline,
    [FilterLocation.name]: FilterLocation,
    [Tag.name]: Tag,
    [Loader.name]: Loader,
    [Advertisement.name]: Advertisement,
  },
  props: {
    isSearch: false,
    mobileFilterActive: false,
    isGroupSearch: false,
    isClubSearch: false,
    isProfile: false,
    isChallenge: false,
    image: null,
    hidelocation: false,
    advertisement: null,
  },
  data: () => ({
    filter: {
      date: null,
      age: null,
      price: null,
      tags: null,
    },
    error: false,
  }),
  computed: {
    ...mapGetters('event', {
      getEventFilter: 'getEventFilter',
    }),
    ...mapGetters('account', {
      currentUser: 'getCurrentUser',
      isLoadingForgotPassword: 'getLoadingStateForgotPassword',
    }),
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
    tags() {
      const tagArray = [];
      const filter = this.getEventFilter;
      if (typeof filter.age !== 'undefined'
        && filter.age !== null
        && filter.age.length !== null
        && filter.age.length > 0) {
        tagArray.push({ name: `${filter.age[0]}-${filter.age[1]} jaar`, type: 'age' });
      }

      if (typeof filter.price !== 'undefined'
        && filter.price !== null
        && filter.price.length !== null
        && filter.price.length > 0) {
        tagArray.push({ name: `${filter.price[0]}-${filter.price[1]} euro`, type: 'price' });
      }

      if (typeof filter.date !== 'undefined'
      && filter.date !== null
      && filter.date.length !== null
      && filter.date.length > 0) {
        const dateFrom = new Date(filter.date[0]);
        const formattedDayFrom = this.$options.filters.formatDate(dateFrom);

        const dateTo = new Date(filter.date[1]);
        const formattedDayTo = this.$options.filters.formatDate(dateTo);

        tagArray.push({ name: `${formattedDayFrom} - ${formattedDayTo}`, type: 'date' });
      }

      if (typeof filter.tags !== 'undefined' && filter.tags !== null) {
        filter.tags.forEach((tag) => {
          tagArray.push({ name: `${tag}`, type: 'tag' });
        });
      }

      if (!isNullOrWhiteSpace(filter.type)) {
        tagArray.push({ name: filter.type, type: 'type' });
      }

      if (filter.discipline !== 0 && filter.discipline !== null) {
        this.discipline = this.disciplineList.find((d) => d.id === filter.discipline);
        tagArray.push({ name: this.discipline.name, type: 'discipline' });
      }

      if (!isNullOrWhiteSpace(filter.location)) {
        tagArray.push({ name: filter.location, type: 'location' });
      }

      return tagArray;
    },
  },
  methods: {
    ...mapMutations('event', {
      setEventFilterDate: 'SET_EVENT_FILTER_DATE',
      setEventFilterAge: 'SET_EVENT_FILTER_AGE',
      setEventFilterPrice: 'SET_EVENT_FILTER_PRICE',
      setEventFilterTags: 'SET_EVENT_FILTER_TAGS',
      setEventFilterType: 'SET_EVENT_FILTER_TYPE',
      setEventFilterDiscipline: 'SET_EVENT_FILTER_DISCIPLINE',
      setEventFilterLocation: 'SET_EVENT_FILTER_LOCATION',
      setEventFilterAvailablePlaces: 'SET_EVENT_FILTER_AVAILABLE_PLACES',
    }),
    deleteTag(tagType) {
      switch (tagType) {
        case 'date':
          this.setEventFilterDate([]);
          break;
        case 'age':
          this.setEventFilterAge([]);
          break;
        case 'availablePlaces':
          this.setEventFilterAvailablePlaces([]);
          break;
        case 'price':
          this.setEventFilterPrice([]);
          break;
        case 'tag':
          this.setEventFilterTags([]);
          break;
        case 'type':
          this.setEventFilterType('');
          break;
        case 'discipline':
          this.setEventFilterDiscipline(null);
          break;
        case 'location':
          this.setEventFilterLocation(null);
          break;
        default:
          break;
      }
    },
    changePassword() {
      this.$emit('changePassword');
    },
  },
};
