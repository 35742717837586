/* eslint-disable no-unused-vars */
import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';

// import { Vuetable, VuetablePagination } from 'vuetable-2';

import _ from 'lodash';

export default {
  name: 'ChallengeRanking',
  components: {
    // Vuetable,
    // VuetablePagination,
  },
  props: ['tableData', 'isWatermanChallenge'],
  data: () => ({
    tableFields: [
      {
        name: '__slot:index',
      },
      {
        name: '__slot:firstname',
      },
      {
        name: '__slot:disciplines',
      },
    ],
    data: null,
  }),
  computed: {
    ...mapGetters('account', {
      currentUser: 'getCurrentUser',
    }),
  },
  methods: {
    onDataManager() {
      const table = _.cloneDeep(this.tableData);
      if (this.currentUser && table.length > 1) table.shift();
      return table;
    },
  },
  watch: {
    tableData() {
      this.$refs.vuetable.refresh();
    },
  },
  created() {
  },
};
